import React from 'react'
export type NavRouteType = {
  path: string
  subPaths?: string[]
  label: string
  icon?: string | React.ReactNode
  subRoutes?: NavRouteType[]
}

const ingressHttpRoutes: NavRouteType[] = [
  {
    path: '/ingress/http/routers',
    subPaths: ['/ingress/http/routers/:name'],
    label: 'HTTP Routers',
  },
  {
    path: '/ingress/http/middlewares',
    subPaths: ['/ingress/http/middlewares/:name'],
    label: 'HTTP Middlewares',
  },
  {
    path: '/ingress/http/services',
    subPaths: ['/ingress/http/services/:name'],
    label: 'HTTP Services',
  },
]

const ingressTcpRoutes: NavRouteType[] = [
  {
    path: '/ingress/tcp/routers',
    subPaths: ['/ingress/tcp/routers/:name'],
    label: 'TCP Routers',
  },
  {
    path: '/ingress/tcp/services',
    subPaths: ['/ingress/tcp/services/:name'],
    label: 'TCP Services',
  },
]

const ingressUdpRoutes: NavRouteType[] = [
  {
    path: '/ingress/udp/routers',
    subPaths: ['/ingress/udp/routers/:name'],
    label: 'UDP Routers',
  },
  {
    path: '/ingress/udp/services',
    subPaths: ['/ingress/udp/services/:name'],
    label: 'UDP Services',
  },
]

const ingressRoutes: NavRouteType[] = [
  { path: '/ingress/overview', label: 'Overview' },
  { path: '/ingress/http', label: 'HTTP', subRoutes: ingressHttpRoutes },
  { path: '/ingress/tcp', label: 'TCP', subRoutes: ingressTcpRoutes },
  { path: '/ingress/udp', label: 'UDP', subRoutes: ingressUdpRoutes },
]

const meshRoutes: NavRouteType[] = [
  {
    path: '/mesh/overview',
    label: 'Overview',
  },
  {
    path: '/mesh/services',
    label: 'Services',
  },
  {
    path: '/mesh/connections',
    label: 'Connections',
    subPaths: ['/mesh/services/:name/connections'],
  },
]

const routes: NavRouteType[] = [
  { path: '/', label: 'Dashboard', icon: 'home-outline' },
  { path: '/cluster', label: 'Cluster', icon: 'layers-outline' },
  {
    path: '/ingress',
    label: 'Ingress',
    icon: (
      <path d="M12,2 L12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 L22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.57076009 6.32667079,2.15206828 11.7200952,2.0038418 L12,2 Z M19.1664757,4 C19.3798046,4 19.5931335,4.08083333 19.7556301,4.24416667 C20.0814566,4.56916667 20.0814566,5.09666667 19.7556301,5.4225 L19.7556301,5.4225 L12.8732735,12.3041667 L17.5048436,12.3333333 C17.9656674,12.3358333 18.336493,12.7116667 18.3331597,13.1716667 C18.3298265,13.63 17.9581675,14 17.4998438,14 L17.4998438,14 L17.4948439,14 L10.8341493,13.9583333 C10.3758255,13.9558333 10.0058332,13.5833333 10.0058332,13.1258333 L10.0058332,13.1258333 L10,6.50083333 C10,6.04083333 10.3724922,5.66666667 10.833316,5.66666667 C11.2933064,5.66666667 11.6666319,6.03916667 11.6666319,6.49916667 L11.6666319,6.49916667 L11.6707985,11.1508333 L18.5773213,4.24416667 C18.7398179,4.08083333 18.9531468,4 19.1664757,4 Z"></path>
    ),
    subRoutes: ingressRoutes,
  },
  { path: '/mesh', label: 'Mesh', icon: 'swap-outline', subRoutes: meshRoutes },
  // { path: '/license', label: 'License', icon: 'file-text-outline' },
]

export default routes
