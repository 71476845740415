import React from 'react'
import Icon from 'react-eva-icons'
import styled, { SimpleInterpolation } from 'styled-components'
import { Chip, Card, Flex, Heading, Text, Box, Tooltip, theme } from '@containous/faency'
import { StatusWrapper } from './ResourceStatus'
import { useNavigator } from '../utils/navigation'

const CustomHeading = styled(Heading)`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes[4]};
`

type SectionHeaderType = {
  icon?: string | undefined
  title?: string | undefined
}

export const SectionHeader: React.FC<SectionHeaderType> = ({ icon, title }) => {
  if (!title) {
    return (
      <CustomHeading mb={4}>
        <Box sx={{ width: 5, height: 4, bg: theme.colors.grays[2], borderRadius: 1 }} />
        <Box ml={1} sx={{ width: '50%', maxWidth: '300px', height: 4, bg: theme.colors.grays[2], borderRadius: 1 }} />
      </CustomHeading>
    )
  }

  return (
    <CustomHeading mb={3}>
      {icon && <Icon name={icon} size="large" fill="black" />}
      <Text ml={1}>{title}</Text>
    </CustomHeading>
  )
}

export const ItemTitle = styled(Text)`
  letter-spacing: 3px;
  color: ${theme.colors.grays[5]};
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: ${theme.space[2]};
  text-align: left;
  font-size: ${theme.fontSizes[0]};
  word-break: break-word;
`

const SpacedCard = styled(Card)`
  & + & {
    margin-top: ${theme.sizes[2]};
  }
`

const CardDescriptionSmall = styled(Text)`
  text-align: left;
  font-weight: 700;
  font-size: ${theme.fontSizes[2]};
  line-height: ${theme.lineHeights[2]};
`

const CardDescriptionBig = styled(Text)`
  text-align: left;
  font-weight: 700;
  font-size: ${theme.fontSizes[5]};
  line-height: ${theme.lineHeights[3]};
`

const CardListColumn = styled(Flex)`
  display: grid;
  grid-template-columns: minmax(160px, 66%) minmax(50px, auto);
`

const ItemBlockContainer = styled(Flex)`
  max-width: 100%;

  // This forces the Tooltips to respect max-width, since we can't define
  // it directly on the component, and the Chips are automatically covered.
  span {
    max-width: 100%;
  }
`

const FlexLink = styled('a')<{ hoverable: boolean }>`
  display: flex;
  flex-flow: column;
  text-decoration: none;

  ${({ hoverable }): SimpleInterpolation => hoverable && `cursor: pointer;`}
`

type CardType = {
  title: string
  description?: string
  focus?: boolean
  link?: string
}

type SectionType = SectionHeaderType & {
  cards?: CardType[] | undefined
  isLast?: boolean
  bigDescription?: boolean
}

export const CardListSection: React.FC<SectionType> = ({ icon, title, cards, isLast, bigDescription }) => {
  const CardDescription = bigDescription ? CardDescriptionBig : CardDescriptionSmall
  const navigate = useNavigator()

  const CardSkeleton = (
    <SpacedCard padding={2}>
      <ItemTitle>
        <Box
          sx={{ height: theme.fontSizes[0], bg: theme.colors.grays[1], borderRadius: 1 }}
          mb={theme.space[2]}
          mr="60%"
        />
      </ItemTitle>
      <CardDescription>
        <Box
          sx={{
            height: bigDescription ? theme.fontSizes[5] : theme.fontSizes[2],
            bg: theme.colors.grays[1],
            borderRadius: 1,
          }}
          mr="20%"
        />
      </CardDescription>
    </SpacedCard>
  )

  return (
    <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
      <SectionHeader icon={icon} title={title} />
      <CardListColumn>
        <Flex sx={{ flexDirection: 'column' }}>
          {!cards && CardSkeleton}
          {cards
            ?.filter(c => !!c.description)
            .map(card => (
              <SpacedCard
                key={card.description}
                padding={2}
                sx={{ border: card.focus ? `2px solid ${theme.colors.blue}` : '' }}
              >
                <FlexLink
                  data-testid={card.link}
                  onClick={(): false | void => !!card.link && navigate(card.link)}
                  hoverable={!!card.link}
                >
                  <ItemTitle>{card.title}</ItemTitle>
                  <CardDescription truncate>{card.description}</CardDescription>
                </FlexLink>
              </SpacedCard>
            ))}
        </Flex>
        {!isLast && (
          <Flex marginX="auto" mt={3}>
            <Icon name="arrow-forward" size="xlarge" fill={theme.colors.grays[4]} />
          </Flex>
        )}
      </CardListColumn>
    </Flex>
  )
}

const FlexCard = styled(Card)`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow-y: auto;
  height: 600px;
`

const NarrowFlexCard = styled(FlexCard)`
  height: 400px;
`

const ItemTitleSkeleton = styled(Box)`
  height: ${theme.sizes[2]};
  background-color: ${theme.colors.grays[1]};
  border-radius: ${theme.radii[1]};
`

const ItemDescriptionSkeleton = styled(Box)`
  height: ${theme.sizes[2]};
  background-color: ${theme.colors.grays[1]};
  border-radius: ${theme.radii[1]};
`

type DetailSectionSkeletonType = {
  narrow?: boolean
}

export const DetailSectionSkeleton: React.FC<DetailSectionSkeletonType> = ({ narrow }) => {
  const Card = narrow ? NarrowFlexCard : FlexCard

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <SectionHeader />
      <Card padding={3}>
        <LayoutTwoCols mb={1}>
          <ItemTitleSkeleton sx={{ width: '40%' }} />
          <ItemTitleSkeleton sx={{ width: '40%' }} />
        </LayoutTwoCols>
        <LayoutTwoCols mb={3}>
          <ItemDescriptionSkeleton sx={{ width: '90%' }} />
          <ItemDescriptionSkeleton sx={{ width: '90%' }} />
        </LayoutTwoCols>
        <Flex mb={1}>
          <ItemTitleSkeleton sx={{ width: '30%' }} />
        </Flex>
        <Flex mb={3}>
          <ItemDescriptionSkeleton sx={{ width: '50%' }} />
        </Flex>
        <Flex mb={1}>
          <ItemTitleSkeleton sx={{ width: '30%' }} />
        </Flex>
        <Flex mb={3}>
          <ItemDescriptionSkeleton sx={{ width: '70%' }} />
        </Flex>
        <Flex mb={1}>
          <ItemTitleSkeleton sx={{ width: '30%' }} />
        </Flex>
        <Flex mb={3}>
          <ItemDescriptionSkeleton sx={{ width: '50%' }} />
        </Flex>
        <LayoutTwoCols mb={1}>
          <ItemTitleSkeleton sx={{ width: '40%' }} />
          <ItemTitleSkeleton sx={{ width: '40%' }} />
        </LayoutTwoCols>
        <LayoutTwoCols mb={3}>
          <ItemDescriptionSkeleton sx={{ width: '90%' }} />
          <ItemDescriptionSkeleton sx={{ width: '90%' }} />
        </LayoutTwoCols>
      </Card>
    </Flex>
  )
}

type DetailSectionType = SectionHeaderType & {
  noPadding?: boolean
  narrow?: boolean
}

export const DetailSection: React.FC<DetailSectionType> = ({ icon, title, children, narrow, noPadding }) => {
  const Card = narrow ? NarrowFlexCard : FlexCard

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <SectionHeader icon={icon} title={title} />
      <Card padding={noPadding ? 0 : 3}>{children}</Card>
    </Flex>
  )
}

const FlexLimited = styled(Flex)`
  max-width: 100%;
  flex-wrap: wrap;
  margin: 0 -8px -8px 0;

  span {
    max-width: 100%;
  }
`

type ChipsType = {
  items: string[]
  variant?: 'blue' | 'gray' | 'green' | 'orange' | 'purple' | 'red' | 'lightBlue'
  alignment?: 'center' | 'left'
}

export const Chips: React.FC<ChipsType> = ({ items, variant = 'lightBlue', alignment = 'left' }) => (
  <FlexLimited>
    {items.map((item: string) => (
      <Tooltip label={item} action="copy" key={item}>
        <Chip variant={variant} sx={{ textAlign: alignment }} mr={1} mb={1} truncate>
          {item}
        </Chip>
      </Tooltip>
    ))}
  </FlexLimited>
)

type ChipPropsListType = {
  data: {
    [key: string]: string
  }
  variant?: 'blue' | 'gray' | 'green' | 'orange' | 'purple' | 'red' | 'lightBlue'
}

export const ChipPropsList: React.FC<ChipPropsListType> = ({ data, variant = 'lightBlue' }) => (
  <Flex sx={{ flexWrap: 'wrap' }}>
    {Object.entries(data).map((entry: [string, string]) => (
      <Chip key={entry[0]} variant={variant} sx={{ textAlign: 'left' }} mr={1} mb={1}>
        {entry[1]}
      </Chip>
    ))}
  </Flex>
)

type ChipStackType = {
  items: string[]
  variant?: 'blue' | 'gray' | 'green' | 'orange' | 'purple' | 'red' | 'lightBlue'
}

export const ChipStack: React.FC<ChipStackType> = ({ items, variant = 'lightBlue' }) => (
  <Flex sx={{ flexDirection: 'column', width: '100%' }}>
    {items.map((item: string) => (
      <Chip key={item} variant={variant} sx={{ textAlign: 'left' }} mb={1} truncate>
        {item}
      </Chip>
    ))}
  </Flex>
)

type IpStrategyBlockType = {
  ipStrategy: {
    depth: number
    excludedIPs: string[]
  }
}

export const IpStrategyBlock: React.FC<IpStrategyBlockType> = ({ ipStrategy }) => (
  <Flex sx={{ flexDirection: 'column' }}>
    {ipStrategy.depth && (
      <Flex mb={1}>
        <Chip variant="blue" mr={1}>
          Depth:
        </Chip>
        <Chip variant="lightBlue">{ipStrategy.depth}</Chip>
      </Flex>
    )}
    {ipStrategy.excludedIPs && (
      <Flex sx={{ flexWrap: 'wrap' }}>
        <Chip variant="blue" mr={1} mb={1}>
          Excluded IPs:
        </Chip>
        {ipStrategy.excludedIPs.map(ip => (
          <Chip key={ip} variant="lightBlue" mr={1} mb={1}>
            {ip}
          </Chip>
        ))}
      </Flex>
    )}
  </Flex>
)

type ItemBlockType = {
  title: string
  children?: Element | Element[] | JSX.Element | JSX.Element[]
}

export const ItemBlock: React.FC<ItemBlockType> = ({ title, children }) => (
  <Flex sx={{ flexDirection: 'column' }} mb={3}>
    <ItemTitle>{title}</ItemTitle>
    <ItemBlockContainer sx={{ alignItems: 'center' }}>{children}</ItemBlockContainer>
  </Flex>
)

const LayoutCols = styled(Box)`
  display: grid;
  grid-gap: ${theme.space[2]};
`

export const LayoutTwoCols = styled(LayoutCols)`
  grid-template-columns: repeat(2, minmax(50%, 1fr));
`

export const LayoutThreeCols = styled(LayoutCols)`
  grid-template-columns: repeat(3, minmax(30%, 1fr));
`

type BooleanStateType = { enabled: boolean }
export const BooleanState: React.FC<BooleanStateType> = ({ enabled }) => (
  <>
    <StatusWrapper color={enabled ? theme.colors.positive : theme.colors.negative}>
      <Icon name={enabled ? 'toggle-right' : 'toggle-left'} size="large" fill="#FFF" />
    </StatusWrapper>
    <Text sx={{ color: enabled ? theme.colors.positive : theme.colors.negative }} ml={1}>
      {enabled ? 'True' : 'False'}
    </Text>
  </>
)

export const ProviderName = styled(Text)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: ${theme.fontSizes[1]};
  color: ${theme.colors.grays[5]};
`

export const EmptyPlaceholder = styled(Text)`
  font-size: ${theme.fontSizes[4]};
  font-weight: 700;
  color: ${theme.colors.grays[4]};
  line-height: 1.2;
`
