import React from 'react'
import { HashRouter as Router, Redirect, Route } from 'react-router-dom'
import { Provider as FaencyProvider, Box, Loading } from '@containous/faency'
import { SWRConfig } from 'swr'
import { QueryParamProvider } from 'use-query-params'
import fetch from './libs/fetch'
import Page from './components/Page'
import { NotificationProvider } from './contexts/notifications'
import { Overview, License, Cluster, IngressPages, MeshPages, NotFound } from '../src/components/pages'
import useClusterInfo from './hooks/use-cluster-info'
import { Switch } from './utils/navigation'

const MeshConnectionsPage = React.lazy(() =>
  import('../src/components/pages/mesh/MeshConnections').then(module => ({ default: module.MeshConnections })),
)

const PageLoader: React.FC = () => (
  <Page>
    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
      <Loading />
    </Box>
  </Page>
)

export const Routes: React.FC = () => {
  const clusterInfo = useClusterInfo()

  if (!clusterInfo) {
    return <PageLoader />
  }

  return (
    <React.Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/ingress/overview" component={IngressPages.Overview} />
        <Route exact path="/ingress/http/routers" component={IngressPages.HttpRouters} />
        <Route exact path="/ingress/http/services" component={IngressPages.HttpServices} />
        <Route exact path="/ingress/http/middlewares" component={IngressPages.HttpMiddlewares} />
        <Route exact path="/ingress/tcp/routers" component={IngressPages.TcpRouters} />
        <Route exact path="/ingress/tcp/services" component={IngressPages.TcpServices} />
        <Route exact path="/ingress/udp/routers" component={IngressPages.UdpRouters} />
        <Route exact path="/ingress/udp/services" component={IngressPages.UdpServices} />
        <Route path="/ingress/http/routers/:name" component={IngressPages.HttpRouter} />
        <Route path="/ingress/http/services/:name" component={IngressPages.HttpService} />
        <Route path="/ingress/http/middlewares/:name" component={IngressPages.HttpMiddleware} />
        <Route path="/ingress/tcp/routers/:name" component={IngressPages.TcpRouter} />
        <Route path="/ingress/tcp/services/:name" component={IngressPages.TcpService} />
        <Route path="/ingress/udp/routers/:name" component={IngressPages.UdpRouter} />
        <Route path="/ingress/udp/services/:name" component={IngressPages.UdpService} />
        {clusterInfo?.platform === 'Kubernetes' && clusterInfo?.meshEnabled === true && (
          <>
            <Redirect exact from="/mesh" to="/mesh/overview" />
            <Route exact path="/mesh/overview" component={MeshPages.MeshOverview} />
            <Route exact path="/mesh/services" component={MeshPages.MeshServices} />
            <Route exact path="/mesh/services/:name/connections" component={MeshConnectionsPage} />
            <Route exact path="/mesh/connections" component={MeshConnectionsPage} />
          </>
        )}
        <Route path="/cluster" component={Cluster} />
        <Route path="/license" component={License} />
        <Route exact path="/" component={Overview} />
        <Redirect exact from="/ingress" to="/ingress/overview" />
        <Redirect exact from="/ingress/http" to="/ingress/http/routers" />
        <Redirect exact from="/ingress/tcp" to="/ingress/tcp/routers" />
        <Redirect exact from="/ingress/udp" to="/ingress/udp/routers" />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </React.Suspense>
  )
}

const isDev = process.env.NODE_ENV === 'development'

const App: React.FC = () => {
  return (
    <NotificationProvider>
      <FaencyProvider>
        <SWRConfig
          value={{
            revalidateOnFocus: !isDev,
            fetcher: fetch,
          }}
        >
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Routes />
            </QueryParamProvider>
          </Router>
        </SWRConfig>
      </FaencyProvider>
    </NotificationProvider>
  )
}

export default App
