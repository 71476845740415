import React, { MutableRefObject, useEffect, useState, useCallback } from 'react'
import { Thead, Th, Td, Box, Tfoot, Tr, Tooltip, Flex } from '@containous/faency'
import { useQueryParams, StringParam } from 'use-query-params'
import qs from 'query-string'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import { EmptyPlaceholder } from '../../EmptyPlaceholder'
import { ChipLimited } from '../../ChipLimited'
import Page from '../../Page'
import { AnimatedTable, AnimatedTBody, AnimatedRow } from '../../AnimatedTable'
import { TableSearch, State as FilterState } from '../../TableFilter'
import useFetchWithPagination, { RenderRowType } from '../../../hooks/use-fetch-with-pagination'
import { NavigatorType, useNavigator } from '../../../utils/navigation'
import AdditionalFeatures from '../../AdditionalFeatures'
import { SpinnerLoader } from '../../SpinnerLoader'
import { ScrollTopButton } from '../../ScrollTopButton'

const makeRowRender = (navigate: NavigatorType): RenderRowType => (row): JSX.Element => (
  <AnimatedRow key={row.name} onClick={(): void => navigate(`/mesh/services/${row.name}@${row.namespace}/connections`)}>
    <Td>
      <Tooltip label={row.name} action="copy">
        <ChipLimited truncate variant="blue">
          {row.name}
        </ChipLimited>
      </Tooltip>
    </Td>
    <Td>
      <Tooltip label={row.namespace} action="copy">
        <ChipLimited truncate variant="purple">
          {row.namespace}
        </ChipLimited>
      </Tooltip>
    </Td>
    <Td style={{ textAlign: 'center' }}>{row.scheme ? row.scheme?.toUpperCase() : '-'}</Td>
    <Td style={{ textAlign: 'center' }}>{row.trafficType?.toUpperCase()}</Td>
    <Td>
      <AdditionalFeatures uid={[row.name, row.namespace].join()} middlewares={row.middlewares} />
    </Td>
  </AnimatedRow>
)

export const MeshServices: React.FC = () => {
  const navigate = useNavigator()
  const renderRow = makeRowRender(navigate)
  const [isMounted, setMounted] = useState(false)
  const [query, setQuery] = useQueryParams({
    search: StringParam,
  })
  const onChangeFilter = useCallback(
    (state: FilterState): void => {
      setQuery(state, 'replaceIn')
    },
    [setQuery],
  )
  const { pages, pageCount, isLoadingMore, isReachingEnd, loadMore, error, isEmpty } = useFetchWithPagination(
    '/mesh/services',
    {
      listContextKey: JSON.stringify(query),
      renderRow,
      renderLoader: () => null,
      query,
    },
  )
  const infiniteRef: MutableRefObject<null | undefined> = useInfiniteScroll({
    loading: isLoadingMore,
    hasNextPage: !isReachingEnd && !error,
    onLoadMore: loadMore,
  })

  useEffect(() => {
    setMounted(true)
    const originalUrlParams = qs.parse(window.location.search)
    setQuery(originalUrlParams)
  }, [])

  return (
    <Page title="Mesh Services">
      <Box mb={3}>
        <TableSearch initialData={query} onChange={onChangeFilter} hideStatusFilter />
      </Box>

      <AnimatedTable infiniteRef={infiniteRef}>
        <Thead>
          <Th style={{ width: '20%' }}>Name</Th>
          <Th style={{ width: '10%' }}>Namespace</Th>
          <Th style={{ width: '15%', textAlign: 'center' }}>Scheme</Th>
          <Th style={{ width: '15%', textAlign: 'center' }}>Traffic Type</Th>
          <Th style={{ width: '40%' }}>Additional Features</Th>
        </Thead>
        <AnimatedTBody pageCount={pageCount} isMounted={isMounted}>
          {pages}
        </AnimatedTBody>
        {(isEmpty || !!error) && (
          <Tfoot>
            <Tr>
              <td colSpan={100}>
                <EmptyPlaceholder message={error ? 'Failed to fetch data' : 'No data available'} />
              </td>
            </Tr>
          </Tfoot>
        )}
      </AnimatedTable>
      <Flex sx={{ height: 60, alignItems: 'center', justifyContent: 'center' }}>
        {isLoadingMore ? <SpinnerLoader /> : isReachingEnd && pageCount > 1 && <ScrollTopButton />}
      </Flex>
    </Page>
  )
}
