const { REACT_APP_BASE_API_URL } = process.env

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function<JSON = any>(
  baseUrl: string,
  params: string[],
  init?: RequestInit,
): Promise<JSON[] | undefined> {
  if (!params) return

  const requests = params.map(param => {
    const apiUrl = `${REACT_APP_BASE_API_URL || ''}${baseUrl}${param}`
    return fetch(apiUrl, init).then(res => res.json())
  })

  // eslint-disable-next-line no-undef
  return await Promise.all(requests)
}
