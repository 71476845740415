import React, { ReactNode } from 'react'
import { Box, Flex, Text, Card, CardLink } from '@containous/faency'
import styled from 'styled-components'
import Status from './Status'
import { motion } from 'framer-motion'

const StatusGrid = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`

type ServiceStatusProps = {
  status?: 'enabled' | 'warning' | 'disabled'
  amount?: number
  total?: number
}

const titleByStatus = {
  enabled: 'Success',
  warning: 'Warnings',
  disabled: 'Errors',
}

const ServiceStatus: React.FC<ServiceStatusProps> = ({ status = 'disabled', amount = 0, total = 0 }) => (
  <Flex sx={{ opacity: amount === 0 ? 0.33 : 1 }}>
    <Status status={status} />
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 600 }}>{titleByStatus[status]}</Text>
      <Flex sx={{ minWidth: '64px' }}>
        <Flex sx={{ flexGrow: 1 }}>
          <Text sx={{ fontWeight: 'bold' }} size={3} data-testid={`${status}-count`}>
            {amount.toFixed(0)}
          </Text>
        </Flex>
        <Text size={1} sx={{ color: 'grays.5', lineHeight: '22px' }}>
          {total > 0 ? ((amount * 100) / total).toFixed(0) : 0}%
        </Text>
      </Flex>
    </Flex>
  </Flex>
)

type StatusDataProps = {
  stats: {
    warnings: number
    errors: number
    total: number
  }
}

const StatusData: React.FC<StatusDataProps> = ({ stats }) => (
  <StatusGrid>
    <ServiceStatus status="enabled" amount={stats.total - stats.warnings - stats.errors} total={stats.total} />
    <ServiceStatus status="warning" amount={stats.warnings} total={stats.total} />
    <ServiceStatus status="disabled" amount={stats.errors} total={stats.total} />
  </StatusGrid>
)

const AnimatedCard = motion.custom(Card)
const AnimatedCardLink = motion.custom(CardLink)

type ComponentWithExploreProps = {
  onExplore?: () => void
}

type StatusCardContainerProps = ComponentWithExploreProps & {
  children: ReactNode
}

const StatusCardContainer: React.FC<StatusCardContainerProps> = ({ onExplore, children }) => {
  if (onExplore) {
    return <AnimatedCardLink onClick={onExplore}>{children}</AnimatedCardLink>
  }

  return <AnimatedCard>{children}</AnimatedCard>
}

type StatusCardProps = StatusDataProps &
  ComponentWithExploreProps & {
    title?: string
  }

export const StatusCard: React.FC<StatusCardProps> = ({ title, stats, onExplore }) => (
  <StatusCardContainer onExplore={onExplore}>
    {title && (
      <Box mb={2}>
        <Text sx={{ fontWeight: 600 }}>{title}</Text>
      </Box>
    )}
    <StatusData stats={stats} />
  </StatusCardContainer>
)
