import React from 'react'
import { Flex, Heading, Chip, Box, Text, theme } from '@containous/faency'
import useRouter from 'use-react-router'
import styled, { SimpleInterpolation } from 'styled-components'
import Page from '../../Page'
import {
  DetailSectionSkeleton,
  DetailSection,
  ItemBlock,
  LayoutTwoCols,
  BooleanState,
  ProviderName,
  Chips,
} from '../../DetailSections'
import { ServiceDetailType, useResourceDetail } from '../../../hooks/use-resource-detail'
import { ProviderIcon } from '../../ProviderIcon'
import { ResourceStatus } from '../../ResourceStatus'
import { UsedByRoutersSkeleton, UsedByRoutersSection } from '../../UsedByRoutersSection'
import { NotFound } from '../NotFound'

const ErrorMessage = styled.div`
  color: ${theme.colors.grays[5]};
`

type DetailProps = {
  data: ServiceDetailType
  protocol?: string
}

const SpacedColumns = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: ${theme.space[2]};
`

const ServicesGrid = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  padding: ${theme.space[2]} ${theme.space[3]};
  border-bottom: 1px solid ${theme.colors.blacks[4]};
`

const ServersGrid = styled(Box)<{ hasStatus: boolean }>`
  display: grid;
  align-items: center;
  padding: ${theme.space[2]} ${theme.space[3]};
  border-bottom: 1px solid ${theme.colors.blacks[4]};

  ${({ hasStatus = false }): SimpleInterpolation =>
    hasStatus &&
    `
    grid-template-columns: 25% auto;
  `}
`

const MirrorsGrid = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  padding: ${theme.space[2]} ${theme.space[3]};
  border-bottom: 1px solid ${theme.colors.blacks[4]};

  > *:not(:first-child) {
    justify-self: flex-end;
  }
`

const GridTitle = styled(Text)`
  font-size: ${theme.fontSizes[1]};
  font-weight: 700;
  color: ${theme.colors.grays[5]};
`

type Server = {
  url: string
  address?: string
}

type ServerStatus = {
  [server: string]: string
}

function getServerStatusList(data: ServiceDetailType): ServerStatus {
  const serversList: ServerStatus = {}

  data.loadBalancer?.servers?.forEach((server: Server) => {
    serversList[server.address || server.url] = 'DOWN'
  })

  if (data.serverStatus) {
    Object.entries(data.serverStatus).forEach(([server, status]) => {
      serversList[server] = status
    })
  }

  return serversList
}

export const ServicePanels: React.FC<DetailProps> = ({ data, protocol = '' }) => {
  const serversList = getServerStatusList(data)
  const getProviderFromName = (serviceName: string): string => {
    const [, provider] = serviceName.split('@')
    return provider || data.provider
  }

  return (
    <SpacedColumns mb={3} pb={3} data-testid="service-details">
      <DetailSection narrow icon="info" title="Service Details">
        <LayoutTwoCols>
          {data.type && (
            <ItemBlock title="Type">
              <Chip variant="lightBlue">{data.type}</Chip>
            </ItemBlock>
          )}
          {data.provider && (
            <ItemBlock title="Provider">
              <ProviderIcon name={data.provider} />
              <ProviderName ml={1}>{data.provider}</ProviderName>
            </ItemBlock>
          )}
        </LayoutTwoCols>
        {data.status && (
          <ItemBlock title="Status">
            <ResourceStatus status={data.status} withLabel />
          </ItemBlock>
        )}
        {data.mirroring && data.mirroring.service && (
          <ItemBlock title="Main Service">
            <Chip variant="lightBlue">{data.mirroring.service}</Chip>
          </ItemBlock>
        )}
        {data.loadBalancer && (
          <>
            {data.loadBalancer.passHostHeader && (
              <ItemBlock title="Pass Host Header">
                <BooleanState enabled={data.loadBalancer.passHostHeader} />
              </ItemBlock>
            )}
            {data.loadBalancer.terminationDelay && (
              <ItemBlock title="Termination Delay">
                <Chip variant="purple">{data.loadBalancer.terminationDelay} ms</Chip>
              </ItemBlock>
            )}
          </>
        )}
      </DetailSection>
      {data.loadBalancer?.healthCheck && (
        <DetailSection narrow icon="shield" title="Health Check">
          <Box data-testid="health-check">
            <LayoutTwoCols>
              {data.loadBalancer.healthCheck.scheme && (
                <ItemBlock title="Scheme">
                  <Chip variant="green">{data.loadBalancer.healthCheck.scheme}</Chip>
                </ItemBlock>
              )}
              {data.loadBalancer.healthCheck.interval && (
                <ItemBlock title="Interval">
                  <Chip>{data.loadBalancer.healthCheck.interval}</Chip>
                </ItemBlock>
              )}
            </LayoutTwoCols>
            <LayoutTwoCols>
              {data.loadBalancer.healthCheck.path && (
                <ItemBlock title="Path">
                  <Chip variant="lightBlue">{data.loadBalancer.healthCheck.path}</Chip>
                </ItemBlock>
              )}
              {data.loadBalancer.healthCheck.timeout && (
                <ItemBlock title="Timeout">
                  <Chip>{data.loadBalancer.healthCheck.timeout}</Chip>
                </ItemBlock>
              )}
            </LayoutTwoCols>
            <LayoutTwoCols>
              {data.loadBalancer.healthCheck.port && (
                <ItemBlock title="Port">
                  <Chip variant="purple">{data.loadBalancer.healthCheck.port}</Chip>
                </ItemBlock>
              )}
              {data.loadBalancer.healthCheck.hostname && (
                <ItemBlock title="Hostname">
                  <Chip variant="blue">{data.loadBalancer.healthCheck.hostname}</Chip>
                </ItemBlock>
              )}
            </LayoutTwoCols>
            {data.loadBalancer.healthCheck.headers && (
              <ItemBlock title="Headers">
                <Chips
                  variant="orange"
                  items={Object.entries(data.loadBalancer.healthCheck.headers).map(entry => entry.join(': '))}
                />
              </ItemBlock>
            )}
          </Box>
        </DetailSection>
      )}
      {!!data?.weighted?.services?.length && (
        <DetailSection narrow icon="globe" title="Services" noPadding>
          <>
            <ServicesGrid mt={1}>
              <GridTitle>Name</GridTitle>
              <GridTitle sx={{ textAlign: 'center' }}>Weight</GridTitle>
              <GridTitle sx={{ textAlign: 'center' }}>Provider</GridTitle>
            </ServicesGrid>
            <Box data-testid="servers-list">
              {data.weighted.services.map(service => (
                <ServicesGrid key={service.name}>
                  <Flex>
                    <Chip variant="blue">{service.name}</Chip>
                  </Flex>
                  <Text size={1} sx={{ textAlign: 'center' }}>
                    {service.weight}
                  </Text>
                  <Flex sx={{ justifyContent: 'center' }}>
                    <ProviderIcon name={getProviderFromName(service.name)} />
                  </Flex>
                </ServicesGrid>
              ))}
            </Box>
          </>
        </DetailSection>
      )}
      {Object.keys(serversList).length > 0 && (
        <DetailSection narrow icon="globe" title="Servers" noPadding>
          <>
            <ServersGrid mt={1} hasStatus={protocol === 'http'}>
              {protocol === 'http' && <GridTitle>Status</GridTitle>}
              <GridTitle>URL</GridTitle>
            </ServersGrid>
            <Box data-testid="servers-list">
              {Object.entries(serversList).map(([server, status]) => (
                <ServersGrid key={server} hasStatus={protocol === 'http'}>
                  {protocol === 'http' && <ResourceStatus status={status === 'UP' ? 'enabled' : 'disabled'} />}
                  <Flex>
                    <Chip variant="blue">{server}</Chip>
                  </Flex>
                </ServersGrid>
              ))}
            </Box>
          </>
        </DetailSection>
      )}
      {data.mirroring?.mirrors && data.mirroring.mirrors.length > 0 && (
        <DetailSection narrow icon="globe" title="Mirror Services" noPadding>
          <MirrorsGrid mt={1}>
            <GridTitle>Name</GridTitle>
            <GridTitle>Percent</GridTitle>
            <GridTitle>Provider</GridTitle>
          </MirrorsGrid>
          <Box data-testid="mirror-services">
            {data.mirroring.mirrors.map(mirror => (
              <MirrorsGrid key={mirror.name}>
                <Flex>
                  <Chip variant="blue">{mirror.name}</Chip>
                </Flex>
                <Text size={1}>{mirror.percent}</Text>
                <ProviderIcon name={getProviderFromName(mirror.name)} />
              </MirrorsGrid>
            ))}
          </Box>
        </DetailSection>
      )}
    </SpacedColumns>
  )
}

export const HttpService: React.FC = () => {
  const { match } = useRouter<{ name: string }>()
  const service = match.params.name
  const { data: detail, error: detailError } = useResourceDetail(service, 'services')

  if (detailError) {
    return (
      <Page title={service}>
        <ErrorMessage>
          Sorry, we could not fetch detail information for this Service right now. Please, try again later.
        </ErrorMessage>
      </Page>
    )
  }

  if (!detail) {
    return (
      <Page title={service}>
        <SpacedColumns>
          <DetailSectionSkeleton narrow />
          <DetailSectionSkeleton narrow />
          <DetailSectionSkeleton narrow />
        </SpacedColumns>
        <UsedByRoutersSkeleton />
      </Page>
    )
  }

  if (!detail.name) {
    return <NotFound />
  }

  return (
    <Page title={service}>
      <Heading size={3} mb={6}>
        {detail.name}
      </Heading>
      <ServicePanels data={detail} protocol="http" />
      <UsedByRoutersSection data={detail} protocol="http" />
    </Page>
  )
}
