import React from 'react'
import { Box, Flex, Text, Card as FaencyCard, theme, Table, Tr, Td as FaencyTd } from '@containous/faency'
import styled, { SimpleInterpolation } from 'styled-components'
import useSWR from 'swr'
import Page from '../Page'

const Card = styled(FaencyCard)`
  flex: 1;
  margin-top: ${theme.space[2]};
  min-width: 600px;
  padding: 0;
  overflow: hidden;
`

const CardHeader = styled(Box)`
  padding: 12px 22px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  ${({ color }): SimpleInterpolation => `
    background-color: ${color};
  `}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
`

const Td = styled(FaencyTd)`
  padding: ${theme.sizes[2]} 0;
  font-size: ${theme.sizes[2]};
  min-width: 220px;
`

const CardContent = styled(Box)`
  padding: 16px 22px;
`

export const License: React.FC = () => {
  const { data: licenseData } = useSWR('/cluster/license')

  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }

  return (
    <Page title="License">
      <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Card>
          <CardHeader color={theme.colors.blues[6]}>
            <Text size={4} sx={{ color: 'white' }}>
              License information
            </Text>
          </CardHeader>
          {licenseData && (
            <CardContent>
              <Table style={{ boxShadow: 'none', border: 'none', width: '100%', margin: 0 }}>
                <Tr>
                  <Td style={{ borderTop: 'none' }}>
                    <Text>Name:</Text>
                  </Td>
                  <Td style={{ borderTop: 'none' }}>
                    <Text>{licenseData.licenseInfo.companyName}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Offer:</Text>
                  </Td>
                  <Td>
                    <Text>{licenseData.licenseInfo.offer}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Expiration date:</Text>
                  </Td>
                  <Td>
                    <Text>{new Date(licenseData.licenseInfo.expiry).toLocaleDateString('en-EN', options)}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Controller nodes:</Text>
                  </Td>
                  <Td>
                    <Text>{licenseData.controllerNodeCount}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Proxy nodes:</Text>
                  </Td>
                  <Td>
                    <Text>
                      {licenseData.proxyNodeCount} / {licenseData.licenseInfo.proxyLimit}
                    </Text>
                  </Td>
                </Tr>
              </Table>
            </CardContent>
          )}
        </Card>
      </Flex>
    </Page>
  )
}
