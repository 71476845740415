import React, { ReactNode } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import Container from './Container'
import Notification from './Notification'
import useNotification from '../hooks/use-notification'
import breakpoints from '../utils/breakpoints'
import { ToastPool } from './ToastPool'
import { ToastProvider } from '../contexts/toasts'
import Header from './Header'

const BodyGlobalStyle = createGlobalStyle`
  body {
    background-color: #f2f3f5;
  }
`

const ResponsiveContainer = styled(Container)`
  padding: 12px;

  @media (min-width: ${breakpoints.laptop}) {
    padding: 24px;
  }
`

export interface Props {
  title?: string
  children?: ReactNode
}

const Page: React.FC<Props> = ({ children, title }) => {
  const notification = useNotification()

  return (
    <ToastProvider>
      <BodyGlobalStyle />
      <Helmet>
        <title>Traefik Enterprise {title ? ` - ${title}` : ''}</title>
      </Helmet>
      <Header />
      <ResponsiveContainer padding={3}>
        {notification && <Notification {...notification} />}
        {children}
      </ResponsiveContainer>
      <ToastPool />
    </ToastProvider>
  )
}

export default Page
