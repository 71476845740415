import { History } from 'history'
import { useHistory, Switch as BaseSwitch } from 'react-router-dom'
import flattenChildren from 'react-flatten-children'
import React from 'react'

export type NavRouteType = {
  path: string
  label: string
  icon?: string
  activeRouteRegex: RegExp
  excludeRegex?: RegExp
}

export type NavigatorType = (path: string) => void

export function useNavigator(): NavigatorType {
  const history = useHistory()
  const { pathname } = history.location

  return (path: string): void => {
    pathname !== path && history.push(path)
  }
}

export function makeNavigator(history: History): (path: string) => void {
  const { pathname } = history.location
  return (path: string): void => {
    pathname !== path && history.push(path)
  }
}

export const Switch: React.FC = ({ children }) => <BaseSwitch>{flattenChildren(children)}</BaseSwitch>
