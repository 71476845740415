import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Flex, Box, Text, Heading, CardLink as FaencyCardLink, Card as FaencyCard, theme } from '@containous/faency'
import styled, { css } from 'styled-components'
import { Doughnut } from 'react-chartjs-2'
import Status from './Status'

const cardStyle = css`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  margin: ${theme.space[2]};
  padding: ${theme.space[2]};
  overflow: hidden;
`

const Card = styled(FaencyCard)`
  ${cardStyle}
`

const CardLink = styled(FaencyCardLink)`
  ${cardStyle}
`

type StatsCardType = {
  onExplore?: () => void
  children: ReactNode
}

const StatsCard: React.FC<StatsCardType> = ({ onExplore, children, ...props }) => {
  if (onExplore) {
    return (
      <CardLink onClick={onExplore} {...props}>
        {children}
      </CardLink>
    )
  }

  return <Card {...props}>{children}</Card>
}

export type TraefikResourceStatsType = {
  title?: string
  errors: number
  total: number
  warnings: number
}

export type TraefikResourceStatsCardProps = TraefikResourceStatsType & {
  onExplore?: () => void
}

export type DataType = {
  datasets: {
    backgroundColor: string[]
    data: React.ReactText[]
  }[]
  labels?: string[]
}

const TraefikResourceStatsCard: React.FC<TraefikResourceStatsCardProps> = ({
  title,
  errors,
  total,
  warnings,
  onExplore,
}) => {
  const defaultData = {
    datasets: [
      {
        backgroundColor: ['#f2f3f5'],
        data: [1],
      },
    ],
  }
  const [data, setData] = useState<DataType>(defaultData)
  const getPercent = useCallback(value => (total > 0 ? (value * 100) / total : 0), [total])

  const getSuccess = useCallback(
    (inPercent = false): number | string => {
      const num = total - (errors + warnings)

      if (inPercent) {
        return getPercent(num).toFixed(0)
      } else {
        return num
      }
    },
    [errors, total, warnings, getPercent],
  )

  const getWarnings = useCallback(
    (inPercent = false): number | string => {
      const num = warnings

      if (inPercent) {
        return getPercent(num).toFixed(0)
      } else {
        return num
      }
    },
    [warnings, getPercent],
  )

  const getErrors = useCallback(
    (inPercent = false): number | string => {
      const num = errors

      if (inPercent) {
        return getPercent(num).toFixed(0)
      } else {
        return num
      }
    },
    [errors, getPercent],
  )

  useEffect(() => {
    const successCount = getSuccess() as number
    const warningsCount = getWarnings() as number
    const errorsCount = getErrors() as number

    if (successCount + warningsCount + errorsCount === 0) {
      setData(defaultData)
      return
    }

    const newData = {
      datasets: [
        {
          backgroundColor: ['#00A697', theme.colors.orange, theme.colors.red],
          data: [successCount, warningsCount, errorsCount],
        },
      ],
      labels: ['Success', 'Warnings', 'Errors'],
    }

    setData(newData)
  }, [errors, warnings, total, getSuccess, getWarnings, getErrors])

  const options = {
    legend: {
      display: false,
    },
    animation: {
      duration: 1000,
    },
    tooltips: {
      enabled: true,
    },
    maintainAspectRatio: false,
  }

  return (
    <StatsCard data-testid="card" onExplore={onExplore}>
      {title && (
        <Flex pb={2}>
          {title && (
            <Flex sx={{ flex: '1', alignItems: 'center' }}>
              <Heading as="h3" size={1}>
                {title}
              </Heading>
            </Flex>
          )}
        </Flex>
      )}
      <Flex sx={{ flex: '1' }}>
        <Box sx={{ width: '50%' }}>
          <Doughnut data={data} options={options} />
        </Box>
        <Box sx={{ width: '50%' }}>
          <Flex sx={{ alignItems: 'center' }} p={1}>
            <Status status="enabled" />
            <Flex sx={{ flexDirection: 'column', flex: 1 }}>
              <Text sx={{ fontWeight: 600 }}>Success</Text>
              <Text size={1} sx={{ color: 'grays.5' }} data-testid="success-pc">
                {getSuccess(true)}%
              </Text>
            </Flex>
            <Text size={5} sx={{ fontWeight: 700 }} data-testid="success-count">
              {getSuccess()}
            </Text>
          </Flex>
          <Flex sx={{ alignItems: 'center' }} p={1}>
            <Status status="warning" />
            <Flex sx={{ flexDirection: 'column', flex: 1 }}>
              <Text sx={{ fontWeight: 600 }}>Warnings</Text>
              <Text size={1} sx={{ color: 'grays.5' }} data-testid="warnings-pc">
                {getWarnings(true)}%
              </Text>
            </Flex>
            <Text size={5} sx={{ fontWeight: 700 }} data-testid="warnings-count">
              {getWarnings()}
            </Text>
          </Flex>
          <Flex sx={{ alignItems: 'center' }} p={1}>
            <Status status="disabled" />
            <Flex sx={{ flexDirection: 'column', flex: 1 }}>
              <Text sx={{ fontWeight: 600 }}>Errors</Text>
              <Text size={1} sx={{ color: 'grays.5' }} data-testid="errors-pc">
                {getErrors(true)}%
              </Text>
            </Flex>
            <Text size={5} sx={{ fontWeight: 700 }} data-testid="errors-count">
              {getErrors()}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </StatsCard>
  )
}

export default TraefikResourceStatsCard
