import React from 'react'
import styled, { SimpleInterpolation } from 'styled-components'
import { Box, Flex } from '@containous/faency'
import { colorByStatus, StatusType } from './Status'
import { statusByNodeStatus } from './ClusterNodeCard'

const StatusDot = styled(Box).attrs((props: { status: string }) => ({
  'data-testid': `status-dot-${props.status}`,
}))<{ status: StatusType }>`
  border-radius: 50%;
  height: 14px;
  width: 14px;

  ${({ status }): SimpleInterpolation => `
    background-color: ${colorByStatus[status] || colorByStatus.disabled}
  `}
`

type StatusDotsProps = {
  stats: {
    total?: number
    warnings?: number
    errors?: number
  }
}

export const StatusDots: React.FC<StatusDotsProps> = ({ stats }) => {
  const errors = stats.errors || 0
  const warnings = stats.warnings || 0
  const total = stats.total || 0
  const success = total - errors - warnings

  return (
    <Flex>
      {success > 0 && <StatusDot status="enabled" mr={1} />}
      {warnings > 0 && <StatusDot status="warning" mr={1} />}
      {errors > 0 && <StatusDot status="disabled" mr={1} />}
    </Flex>
  )
}

type ObjectWithStatus = {
  status: 'UNKNOWN' | 'READY' | 'DISCONNECTED' | 'DOWN'
}

type StatusDotsNodesProps = {
  nodes: ObjectWithStatus[]
}

export const StatusDotsNodes: React.FC<StatusDotsNodesProps> = ({ nodes }) => (
  <Flex>
    {nodes.find(n => statusByNodeStatus[n.status] === 'enabled') && <StatusDot status="enabled" mr={1} />}
    {nodes.find(n => statusByNodeStatus[n.status] === 'warning') && <StatusDot status="warning" mr={1} />}
    {nodes.find(n => statusByNodeStatus[n.status] === 'disabled') && <StatusDot status="disabled" mr={1} />}
  </Flex>
)
