import React, { useContext, useEffect } from 'react'
import Page from '../../Page'
import { Flex, theme } from '@containous/faency'
import { DetailSectionSkeleton, CardListSection } from '../../DetailSections'
import styled from 'styled-components'
import { ResourceDetailDataType, EntryPoint, useResourceDetail } from '../../../hooks/use-resource-detail'
import RouterPanel from '../../RouterPanel'
import TlsPanel from '../../TlsPanel'
import MiddlewarePanel from '../../MiddlewarePanel'
import useRouter from 'use-react-router'
import { parseMiddlewareType } from '../../../libs/parsers'
import { ToastContext } from '../../../contexts/toasts'
import { getValidData, getErrorData } from '../../../libs/objectHandlers'
import { NotFound } from '../NotFound'

const ErrorMessage = styled.div`
  color: ${theme.colors.grays[5]};
`

const CardListColumns = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: ${theme.space[6]};
`

type DetailProps = {
  data: ResourceDetailDataType
  protocol?: string
}

export const RouterStructure: React.FC<DetailProps> = ({ data, protocol = 'http' }) => {
  const { addToast } = useContext(ToastContext)
  const entrypoints = getValidData(data.entryPointsData)
  const entrypointsError = getErrorData(data.entryPointsData)
  const serviceSlug = data.service.includes('@') ? data.service : `${data.service}@${data.provider}`

  useEffect(() => {
    entrypointsError?.map(error =>
      addToast({
        message: error.message,
        severity: 'error',
      }),
    )
  }, [addToast, entrypointsError])

  return (
    <CardListColumns data-testid="router-structure">
      {entrypoints.length > 0 && (
        <CardListSection
          bigDescription
          icon="log-in-outline"
          title="Entrypoints"
          cards={data.entryPointsData?.map((ep: EntryPoint) => ({
            title: ep.name,
            description: ep.address,
          }))}
        />
      )}
      <CardListSection
        icon="globe-outline"
        title={`${protocol.toUpperCase()} Router`}
        cards={[{ title: 'router', description: data.name, focus: true }]}
      />
      {data.hasValidMiddlewares && (
        <CardListSection
          icon="layers-outline"
          title={`${protocol.toUpperCase()} Middlewares`}
          cards={data.middlewares?.map(mw => ({
            title: 'middleware',
            description: parseMiddlewareType(mw),
            link: `/ingress/${protocol}/middlewares/${mw.name}`,
          }))}
        />
      )}
      <CardListSection
        isLast
        icon="flash"
        title="Service"
        cards={[{ title: 'service', description: data.service, link: `/ingress/${protocol}/services/${serviceSlug}` }]}
      />
    </CardListColumns>
  )
}

const SpacedColumns = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: ${theme.space[2]};
`

const RouterDetail: React.FC<DetailProps> = ({ data }) => (
  <SpacedColumns data-testid="router-detail">
    <RouterPanel data={data} />
    <TlsPanel data={data} />
    <MiddlewarePanel data={data} />
  </SpacedColumns>
)

export const HttpRouter: React.FC = () => {
  const {
    match: {
      params: { name: service },
    },
  } = useRouter<{ name: string }>()
  const { data: detail, error: detailError } = useResourceDetail(service, 'routers')

  if (detailError) {
    return (
      <Page title={service}>
        <ErrorMessage>
          Sorry, we could not fetch detail information for this Router right now. Please, try again later.
        </ErrorMessage>
      </Page>
    )
  }

  if (!detail) {
    return (
      <Page title={service}>
        <Flex sx={{ flexDirection: 'row' }} mb={theme.space[9]}>
          <CardListSection bigDescription />
          <CardListSection />
          <CardListSection />
          <CardListSection isLast />
        </Flex>
        <SpacedColumns>
          <DetailSectionSkeleton />
          <DetailSectionSkeleton />
          <DetailSectionSkeleton />
        </SpacedColumns>
      </Page>
    )
  }

  if (!detail.name) {
    return <NotFound />
  }

  return (
    <Page title={service}>
      <RouterStructure data={detail} protocol="http" />
      <RouterDetail data={detail} />
    </Page>
  )
}
