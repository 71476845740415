import React from 'react'
import Page from '../Page'
import { Heading, Box, Flex, Text, Button } from '@containous/faency'
import { useHistory } from 'react-router-dom'

export const NotFound: React.FC = () => {
  const history = useHistory()

  return (
    <Page>
      <Flex p={4} sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Box>
          <Heading style={{ fontSize: '80px', lineHeight: '120px' }}>404</Heading>
        </Box>
        <Box pb={2}>
          <Text size={6}>I&apos;m sorry, nothing around here...</Text>
        </Box>
        <Button variant="primary" onClick={history.goBack}>
          Go back
        </Button>
      </Flex>
    </Page>
  )
}
