import React from 'react'
import Icon from 'react-eva-icons'
import { theme } from '@containous/faency'
import { motion } from 'framer-motion'

export const SpinnerLoader: React.FC = () => (
  <motion.div
    style={{
      height: '30px',
      width: '30px',
    }}
    animate={{
      rotate: 360,
    }}
    transition={{ ease: 'linear', duration: 1, loop: Infinity }}
  >
    <Icon name="loader-outline" fill={theme.colors.primary} size="xlarge" />
  </motion.div>
)
