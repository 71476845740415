import React from 'react'
import { Chip } from '@containous/faency'
import styled, { SimpleInterpolation } from 'styled-components'
import Icon from 'react-eva-icons'

const iconByStatus: { [key: string]: string } = {
  enabled: 'checkmark-circle-2',
  warning: 'alert-circle',
  disabled: 'alert-triangle',
}

export const colorByStatus: { [key: string]: string } = {
  enabled: '#00A697',
  warning: '#db7d11',
  disabled: '#ff0039',
}

export type StatusType = 'enabled' | 'warning' | 'disabled'

export type StatusProps = {
  status: StatusType
}

const Status = styled(Chip).attrs((props: StatusProps) => ({
  children: <Icon name={iconByStatus[props.status]} size="large" />,
}))<StatusProps>`
  height: 32px;
  width: 32px;
  margin-right: 10px;
  padding: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ status }): SimpleInterpolation => `
    background-color: ${colorByStatus[status]};
  `}

  svg {
    fill: white;
  }
`
export default Status
