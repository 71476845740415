import * as React from 'react'

interface NotificationProviderProps {
  children: React.ReactElement
}

interface NotificationState {
  isVisible: boolean
}

interface NotificationContextProps extends NotificationState {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
}

export const NotificationContext = React.createContext({} as NotificationContextProps)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const NotificationProvider = (props: NotificationProviderProps) => {
  const [isVisible, setVisibility] = React.useState<boolean>(true)
  const value: NotificationContextProps = { isVisible, setVisibility }

  return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>
}
