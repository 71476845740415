import React from 'react'
import { DetailSection, ItemBlock, BooleanState, EmptyPlaceholder } from './DetailSections'
import { Flex, Chip } from '@containous/faency'
import { RouterDetailType } from '../hooks/use-resource-detail'

type Props = {
  data: RouterDetailType
}

const TlsPanel: React.FC<Props> = ({ data }) => (
  <DetailSection icon="shield" title="TLS">
    {data.tls ? (
      <Flex sx={{ flexDirection: 'column' }}>
        <ItemBlock title="TLS">
          <BooleanState enabled />
        </ItemBlock>
        {data.tls.options && (
          <ItemBlock title="Options">
            <Chip variant="green" truncate>
              {data.tls.options}
            </Chip>
          </ItemBlock>
        )}
        <ItemBlock title="PassThrough">
          <BooleanState enabled={!!data.tls.passthrough} />
        </ItemBlock>
        {data.tls.certResolver && (
          <ItemBlock title="Certificate Resolver">
            <Chip variant="orange" truncate>
              {data.tls.certResolver}
            </Chip>
          </ItemBlock>
        )}
        {data.tls.domains && (
          <ItemBlock title="Domains">
            <Flex sx={{ flexDirection: 'column' }}>
              {data.tls.domains?.map(domain => (
                <Flex key={domain.main} sx={{ flexWrap: 'wrap' }}>
                  <a href={`//${domain.main}`}>
                    <Chip mr={1} mb={1} variant="blue" truncate>
                      {domain.main}
                    </Chip>
                  </a>
                  {domain.sans?.map(sub => (
                    <a key={sub} href={`//${sub}`}>
                      <Chip mr={1} mb={1} variant="lightBlue" truncate>
                        {sub}
                      </Chip>
                    </a>
                  ))}
                </Flex>
              ))}
            </Flex>
          </ItemBlock>
        )}
      </Flex>
    ) : (
      <Flex sx={{ flexDirection: 'column', flexGrow: 1, textAlign: 'center', justifyContent: 'center' }}>
        <img src="img/middlewares-empty.svg" alt="Empty TLS" />
        <EmptyPlaceholder mt={2}>TLS is not configured</EmptyPlaceholder>
      </Flex>
    )}
  </DetailSection>
)

export default TlsPanel
