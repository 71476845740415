import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Card as FaencyCard,
  Heading as FaencyHeading,
  theme,
  Thead,
  Tfoot,
  Tr,
  Th,
  Td,
  Tooltip,
} from '@containous/faency'
import styled from 'styled-components'
import Icon from 'react-eva-icons'
import useSWR from 'swr'
import { motion } from 'framer-motion'
import Page from '../../Page'
import { ProviderIcon } from '../../ProviderIcon'
import { TraefikResourceStatsCardProps } from '../../TraefikResourceStatsCard'
import FeatureCard, { FeatureCardProps } from '../../FeatureCard'
import { StatusDots } from '../../StatusDots'
import { StatusCard } from '../../StatusCard'
import { AnimatedTable, AnimatedTBody, AnimatedRow } from '../../AnimatedTable'
import { EmptyPlaceholder } from '../../EmptyPlaceholder'
import { RenderRowType } from '../../../hooks/use-fetch-with-pagination'
import { ResourceStatus } from '../../ResourceStatus'
import { SpinnerLoader } from '../../SpinnerLoader'
import { ChipLimited } from '../../ChipLimited'
import { useHistory } from 'react-router-dom'

const Heading = styled(FaencyHeading)`
  align-items: center;
  display: flex;

  i {
    padding-right: 8px;
  }
`

const CardWrapper = styled(Box)`
  display: grid;
  overflow: hidden;
`

const Card = styled(FaencyCard)`
  margin: ${theme.space[2]};
  padding: 0;
  overflow: hidden;
`

const sumStats = (...stats: TraefikResourceStatsCardProps[]): TraefikResourceStatsCardProps => {
  return stats.reduce((acc, current) => {
    acc.total = current.total
    acc.warnings = current.warnings
    acc.errors = current.errors
    return acc
  }, {} as TraefikResourceStatsCardProps)
}

type Entrypoint = {
  name: string
  address: string
}

const providerIconByProviderName: { [key: string]: string } = {
  KubernetesIngress: 'kubernetes',
  KubernetesCRD: 'kubernetes',
}

const AnimatedCardWrapper = motion.custom(CardWrapper)
const AnimatedFaencyCard = motion.custom(FaencyCard)
const AnimatedCard = motion.custom(Card)

const renderRow: RenderRowType = ({ row }) => (
  <AnimatedRow key={row.name}>
    <Td style={{ width: '25%' }}>
      <ResourceStatus status={row.status} />
    </Td>
    <Td style={{ width: '25%' }}>{row.protocol && row.protocol.toUpperCase()}</Td>
    <Td style={{ width: '25%' }}>{row.type}</Td>
    <Td style={{ width: '25%' }}>
      <Tooltip label={row.name} action="copy">
        <ChipLimited variant="blue" truncate>
          {row.name}
        </ChipLimited>
      </Tooltip>
    </Td>
    <Td style={{ width: '25%' }}>
      <Text sx={{ color: row.status && row.status.toLowerCase() === 'error' ? 'red' : 'orange', fontWeight: 600 }}>
        {row.message}
      </Text>
    </Td>
  </AnimatedRow>
)

export const Overview: React.FC = () => {
  const [isMounted, setMounted] = useState(false)
  const { data: entrypoints } = useSWR('/entrypoints')
  const { data: overviewData } = useSWR('/overview')
  const { data: ingressErrors } = useSWR('/errors')
  const history = useHistory()

  const providers: string[] = overviewData?.providers || []
  const httpRouterStats: TraefikResourceStatsCardProps = overviewData?.http?.routers
  const httpServiceStats: TraefikResourceStatsCardProps = overviewData?.http?.services
  const httpMiddlewareStats: TraefikResourceStatsCardProps = overviewData?.http?.middlewares
  const tcpRouterStats: TraefikResourceStatsCardProps = overviewData?.tcp?.routers
  const tcpServiceStats: TraefikResourceStatsCardProps = overviewData?.tcp?.services
  const udpRouterStats: TraefikResourceStatsCardProps = overviewData?.udp?.routers
  const udpServiceStats: TraefikResourceStatsCardProps = overviewData?.udp?.services

  const featuresObj = overviewData?.features || {}
  const features: FeatureCardProps[] = Object.keys(featuresObj).map((key: string) => {
    return { name: key, value: featuresObj[key] }
  })

  useEffect(() => {
    setMounted(true)
  }, [])

  const animatedListProps = {
    variants: {
      hidden: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
      visible: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
      },
    },
  }

  const animatedRowProps = {
    variants: {
      hidden: {
        x: -6,
        opacity: 0,
      },
      visible: {
        x: 0,
        opacity: 1,
      },
    },
    initial: 'hidden',
  }

  return (
    <Page data-testid="overview-page">
      {(httpRouterStats || httpServiceStats || httpMiddlewareStats) && (
        <Box mb={3}>
          <Heading mb={1} data-testid="http-resources-title">
            <Icon name="globe-outline" size="large" fill="black" />
            <Text mr={2}>HTTP</Text>
            <StatusDots stats={sumStats(httpRouterStats, httpServiceStats, httpMiddlewareStats)} />
          </Heading>
          <AnimatedCardWrapper
            style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', gridGap: '36px' }}
            data-testid="http-resources"
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {httpRouterStats && (
              <motion.div {...animatedRowProps}>
                <StatusCard
                  title="Routers"
                  stats={httpRouterStats}
                  onExplore={(): void => history.push('/ingress/http/routers')}
                />
              </motion.div>
            )}
            {httpServiceStats && (
              <motion.div {...animatedRowProps}>
                <StatusCard
                  title="Services"
                  stats={httpServiceStats}
                  onExplore={(): void => history.push('/ingress/http/services')}
                />
              </motion.div>
            )}
            {httpMiddlewareStats && (
              <motion.div {...animatedRowProps}>
                <StatusCard
                  title="Middlewares"
                  stats={httpMiddlewareStats}
                  onExplore={(): void => history.push('/ingress/http/middlewares')}
                />
              </motion.div>
            )}
          </AnimatedCardWrapper>
        </Box>
      )}
      {(tcpRouterStats || tcpServiceStats) && (
        <Box mb={3}>
          <Heading mb={1} data-testid="tcp-resources-title">
            <Icon name="globe-2-outline" size="large" fill="black" />
            <Text mr={2}>TCP</Text>
            <StatusDots stats={sumStats(tcpRouterStats, tcpServiceStats)} />
          </Heading>
          <AnimatedCardWrapper
            style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', gridGap: '36px' }}
            data-testid="tcp-resources"
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {tcpRouterStats && (
              <motion.div {...animatedRowProps}>
                <StatusCard
                  title="Routers"
                  stats={tcpRouterStats}
                  onExplore={(): void => history.push('/ingress/tcp/routers')}
                />
              </motion.div>
            )}
            {tcpServiceStats && (
              <motion.div {...animatedRowProps}>
                <StatusCard
                  title="Services"
                  stats={tcpServiceStats}
                  onExplore={(): void => history.push('/ingress/tcp/services')}
                />
              </motion.div>
            )}
          </AnimatedCardWrapper>
        </Box>
      )}
      {(udpRouterStats || udpServiceStats) && (
        <Box mb={3}>
          <Heading mb={1} data-testid="udp-resources-title">
            <Icon name="globe-2-outline" size="large" fill="black" />
            <Text mr={2}>UDP</Text>
            <StatusDots stats={sumStats(udpRouterStats, udpServiceStats)} />
          </Heading>
          <AnimatedCardWrapper
            style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', gridGap: '36px' }}
            data-testid="udp-resources"
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {udpRouterStats && (
              <motion.div {...animatedRowProps}>
                <StatusCard
                  title="Routers"
                  stats={udpRouterStats}
                  onExplore={(): void => history.push('/ingress/udp/routers')}
                />
              </motion.div>
            )}
            {udpServiceStats && (
              <motion.div {...animatedRowProps}>
                <StatusCard
                  title="Services"
                  stats={udpServiceStats}
                  onExplore={(): void => history.push('/ingress/udp/services')}
                />
              </motion.div>
            )}
          </AnimatedCardWrapper>
        </Box>
      )}
      <Box mb={3} data-testid="errors-block">
        <Heading mb={1}>
          <Icon name="alert-triangle-outline" size="large" fill="black" />
          Errors
        </Heading>
        {/* InfiniteRef and pageCount set to fixed values until we have pagination for Mesh Errors */}
        <AnimatedTable infiniteRef={null}>
          <Thead>
            <Tr>
              <Th style={{ width: '10%' }}>Status</Th>
              <Th style={{ width: '15%' }}>Section</Th>
              <Th style={{ width: '15%' }}>Type</Th>
              <Th style={{ width: '20%' }}>Name</Th>
              <Th style={{ width: '40%' }}>Message</Th>
            </Tr>
          </Thead>
          <AnimatedTBody pageCount={1} isMounted={isMounted}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {ingressErrors && ingressErrors.map((row: any): JSX.Element => renderRow({ row }))}
          </AnimatedTBody>
          {ingressErrors && ingressErrors.length === 0 && (
            <Tfoot>
              <Tr>
                <td colSpan={100}>
                  <EmptyPlaceholder />
                </td>
              </Tr>
            </Tfoot>
          )}
        </AnimatedTable>
        {!ingressErrors && (
          <Flex sx={{ height: 60, alignItems: 'center', justifyContent: 'center' }}>
            <SpinnerLoader />
          </Flex>
        )}
      </Box>
      {entrypoints?.length > 0 && (
        <Box mb={3}>
          <Heading mb={1} data-testid="entrypoints-title">
            <Icon name="log-in-outline" size="large" fill="black" />
            Entrypoints
          </Heading>
          <AnimatedCardWrapper
            sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))', mx: -2 }}
            data-testid="entrypoints-items"
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {entrypoints.map((entrypoint: Entrypoint) => (
              <AnimatedFaencyCard key={entrypoint.name} padding={2} margin={2} {...animatedRowProps}>
                <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Text pb={2} sx={{ color: 'grays.5', fontWeight: 600 }} style={{ fontSize: 14, letterSpacing: 3 }}>
                    {entrypoint.name.toUpperCase()}
                  </Text>
                  <Text size={8} sx={{ fontWeight: 700 }}>
                    {entrypoint.address}
                  </Text>
                </Flex>
              </AnimatedFaencyCard>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
      {features?.length > 0 && (
        <Box mb={3}>
          <Heading mb={1} data-testid="features-title">
            <Icon name="toggle-right-outline" size="large" fill="black" />
            Features
          </Heading>
          <AnimatedCardWrapper
            sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))', mx: -2 }}
            data-testid="features-items"
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {features.map(feature => (
              <motion.div {...animatedRowProps} key={feature.name}>
                <FeatureCard {...feature} />
              </motion.div>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
      {providers?.length > 0 && (
        <Box mb={3}>
          <Heading mb={1} data-testid="providers-title">
            <Icon name="cube-outline" size="large" fill="black" />
            Providers
          </Heading>
          <AnimatedCardWrapper
            sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))', mx: -2 }}
            data-testid="providers-items"
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {providers.map(provider => (
              <AnimatedCard style={{ height: 168 }} key={provider} {...animatedRowProps}>
                <Flex sx={{ height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <ProviderIcon name={providerIconByProviderName[provider] || provider.toLowerCase()} size={52} />
                  <Heading mt={1} size={1}>
                    {provider}
                  </Heading>
                </Flex>
              </AnimatedCard>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
    </Page>
  )
}
