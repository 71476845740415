import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box, Button, Flex, Text, theme } from '@containous/faency'
import Icon from 'react-eva-icons'
import { motion, AnimatePresence } from 'framer-motion'

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
`

const ToastContainer = styled(Flex)`
  margin-bottom: ${theme.space[2]};
  width: 100%;
  position: relative;
  padding: ${theme.space[3]};
  border-radius: ${theme.radii[3]};
`

const AnimatedToastContainer = motion.custom(ToastContainer)

const toastVariants = {
  create: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    x: '100%',
    scale: 0,
  },
}

export type ToastState = {
  severity: 'error' | 'warning' | 'info' | 'success'
  message?: string
  isVisible?: boolean
  key?: string
}

type ToastProps = ToastState & {
  dismiss: () => void
  icon?: string
  timeout?: number
}

export const Toast: React.FC<ToastProps> = ({
  message,
  dismiss,
  severity = 'error',
  icon = null,
  isVisible = true,
  timeout = 0,
}) => {
  useEffect(() => {
    if (timeout) {
      setTimeout(() => dismiss(), timeout)
    }
  }, [timeout, dismiss])

  const propsBySeverity = {
    error: {
      color: theme.colors.negative,
      icon: 'alert-triangle',
    },
    warning: {
      color: theme.colors.warning,
      icon: 'alert-triangle',
    },
    info: {
      color: theme.colors.blues[4],
      icon: 'alert-circle',
    },
    success: {
      color: theme.colors.positive,
      icon: 'checkmark-circle-2',
    },
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <AnimatedToastContainer
          sx={{ bg: propsBySeverity[severity].color }}
          initial="create"
          animate="visible"
          exit="hidden"
          variants={toastVariants}
        >
          <Box sx={{ height: 3 }} mr={2}>
            <Icon name={icon || propsBySeverity[severity].icon} fill="#fff" size="large" />
          </Box>
          <Text sx={{ lineHeight: 3, color: '#fff', fontWeight: 600 }}>{message}</Text>
          {!timeout && (
            <CloseButton onClick={(): void => dismiss()}>
              <Icon name="close-outline" fill="#fff" size="large" />
            </CloseButton>
          )}
        </AnimatedToastContainer>
      )}
    </AnimatePresence>
  )
}
