import React, { useState } from 'react'
import { Button } from '@containous/faency'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export const ScrollTopButton: React.FC = () => {
  const [showOnScroll, setShowOnScroll] = useState<boolean>(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y

      if (isShow !== showOnScroll) {
        setShowOnScroll(isShow)
      }
    },
    [setShowOnScroll],
  )

  if (!showOnScroll) {
    return null
  }

  return (
    <Button variant="primary" onClick={(): void => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      BACK TO TOP
    </Button>
  )
}
