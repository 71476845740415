import useSWR from 'swr'

type PlatformKind = 'Swarm' | 'Kubernetes' | 'OnPremise'

type InfoType =
  | {
      platform?: PlatformKind
      meshEnabled?: boolean
    }
  | undefined

type ClusterInfoProps = InfoType & {
  message?: string
  severity?: string
}

const useClusterInfo = (): ClusterInfoProps | null => {
  const { data, error } = useSWR('/cluster/overview')

  if (error) {
    return { message: 'Unable to retrieve Cluster information.', severity: 'error' }
  }

  const platform = data?.platform
  const meshEnabled = data?.mesh

  if (!platform) {
    return null
  }

  return { platform, meshEnabled }
}

export default useClusterInfo
