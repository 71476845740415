import React from 'react'
import { DetailSection, ItemBlock, ProviderName, LayoutTwoCols } from './DetailSections'
import { Flex, Chip, Tooltip } from '@containous/faency'
import { ResourceStatus } from './ResourceStatus'
import { ProviderIcon } from './ProviderIcon'
import { ResourceDetailDataType } from '../hooks/use-resource-detail'
import exists from '../utils/exists'

type Props = {
  data: ResourceDetailDataType
}

const RouterPanel: React.FC<Props> = ({ data }) => (
  <DetailSection icon="info" title="Router Details">
    <LayoutTwoCols>
      {data.status && (
        <ItemBlock title="Status">
          <ResourceStatus status={data.status} withLabel />
        </ItemBlock>
      )}
      {data.provider && (
        <ItemBlock title="Provider">
          <ProviderIcon name={data.provider} />
          <ProviderName ml={1}>{data.provider}</ProviderName>
        </ItemBlock>
      )}
    </LayoutTwoCols>
    {data.rule && (
      <ItemBlock title="Rule">
        <Tooltip label={data.rule} action="copy">
          <Chip variant="blue" truncate>
            {data.rule}
          </Chip>
        </Tooltip>
      </ItemBlock>
    )}
    {data.name && (
      <ItemBlock title="Name">
        <Tooltip label={data.name} action="copy">
          <Chip variant="purple" truncate>
            {data.name}
          </Chip>
        </Tooltip>
      </ItemBlock>
    )}
    {exists(data.using) && data.using && data.using.length > 0 && (
      <ItemBlock title="Entrypoints">
        {data.using.map(ep => (
          <Tooltip key={ep} label={ep} action="copy">
            <Chip key={ep} variant="lightBlue" mr={1} truncate>
              {ep}
            </Chip>
          </Tooltip>
        ))}
      </ItemBlock>
    )}
    {data.service && (
      <ItemBlock title="Service">
        <Tooltip label={data.service} action="copy">
          <Chip variant="orange" truncate>
            {data.service}
          </Chip>
        </Tooltip>
      </ItemBlock>
    )}
    {data.error && (
      <ItemBlock title="Errors">
        <Flex sx={{ flexDirection: 'column', width: '100%' }}>
          {data.error.map(error => (
            <Tooltip key={error} label={error} action="copy">
              <Chip key={error} variant="red" sx={{ textAlign: 'left', mr: 1 }} truncate>
                {error}
              </Chip>
            </Tooltip>
          ))}
        </Flex>
      </ItemBlock>
    )}
  </DetailSection>
)

export default RouterPanel
