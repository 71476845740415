import React from 'react'
import useRouter from 'use-react-router'
import styled from 'styled-components'
import { Flex, Heading, theme } from '@containous/faency'
import Page from '../../Page'
import { DetailSectionSkeleton } from '../../DetailSections'
import { useResourceDetail } from '../../../hooks/use-resource-detail'
import { UsedByRoutersSkeleton, UsedByRoutersSection } from '../../UsedByRoutersSection'
import { ServicePanels } from './HttpService'
import { NotFound } from '../NotFound'

const ErrorMessage = styled.div`
  color: ${theme.colors.grays[5]};
`

const SpacedColumns = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: ${theme.space[2]};
`

export const TcpService: React.FC = () => {
  const { match } = useRouter<{ name: string }>()
  const service = match.params.name
  const { data: detail, error: detailError } = useResourceDetail(service, 'services', 'tcp')

  if (detailError) {
    return (
      <Page title={service}>
        <ErrorMessage>
          Sorry, we could not fetch detail information for this Service right now. Please, try again later.
        </ErrorMessage>
      </Page>
    )
  }

  if (!detail) {
    return (
      <Page title={service}>
        <SpacedColumns>
          <DetailSectionSkeleton narrow />
          <DetailSectionSkeleton narrow />
        </SpacedColumns>
        <UsedByRoutersSkeleton />
      </Page>
    )
  }

  if (!detail.name) {
    return <NotFound />
  }

  return (
    <Page title={service}>
      <Heading size={3} mb={6}>
        {detail.name}
      </Heading>
      <ServicePanels data={detail} />
      <UsedByRoutersSection data={detail} protocol="tcp" />
    </Page>
  )
}
