import React from 'react'
import useRouter from 'use-react-router'
import { Flex, theme } from '@containous/faency'
import styled from 'styled-components'
import { ResourceDetailDataType, useResourceDetail } from '../../../hooks/use-resource-detail'
import { DetailSectionSkeleton, CardListSection } from '../../DetailSections'
import RouterPanel from '../../RouterPanel'
import Page from '../../Page'
import { RouterStructure } from './HttpRouter'

const ErrorMessage = styled.div`
  color: ${theme.colors.grays[5]};
`

type DetailProps = {
  data: ResourceDetailDataType
}

const SpacedColumns = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: ${theme.space[2]};
`

const RouterDetail: React.FC<DetailProps> = ({ data }) => (
  <SpacedColumns data-testid="router-details">
    <RouterPanel data={data} />
  </SpacedColumns>
)

export const UdpRouter: React.FC = () => {
  const {
    match: {
      params: { name: service },
    },
  } = useRouter<{ name: string }>()
  const { data: detail, error: detailError } = useResourceDetail(service, 'routers', 'udp')

  if (detailError) {
    return (
      <Page title={service}>
        <ErrorMessage>
          Sorry, we could not fetch detail information for this Router right now. Please, try again later.
        </ErrorMessage>
      </Page>
    )
  }

  if (!detail) {
    return (
      <Page title={service}>
        <Flex sx={{ flexDirection: 'row' }} mb={theme.space[9]}>
          <CardListSection bigDescription />
          <CardListSection />
          <CardListSection isLast />
        </Flex>
        <SpacedColumns>
          <DetailSectionSkeleton />
          <DetailSectionSkeleton />
        </SpacedColumns>
      </Page>
    )
  }

  return (
    <Page title={service}>
      <RouterStructure data={detail} protocol="udp" />
      <RouterDetail data={detail} />
    </Page>
  )
}
