import React from 'react'
import { Box, Flex, Text, Card as FaencyCard, theme } from '@containous/faency'
import styled from 'styled-components'
import Status, { StatusType, colorByStatus } from './Status'
import LeaderIcon from './LeaderIcon'

const Card = styled(FaencyCard)`
  min-width: 350px;
  margin: ${theme.space[2]};
  padding: 0;
  overflow: hidden;
  position: relative;
`

const CardContent = styled(Box)`
  padding: 16px 22px;
`

type ClusterNodeStatus = 'UNKNOWN' | 'READY' | 'DISCONNECTED' | 'DOWN'

type ClusterNodeProps = {
  id: string
  name: string
  ip: string
  status: ClusterNodeStatus
  isLeader: boolean
}

type StatusByNodeStatusType = {
  [key in ClusterNodeStatus]: StatusType
}

export const statusByNodeStatus: StatusByNodeStatusType = {
  READY: 'enabled',
  UNKNOWN: 'warning',
  DISCONNECTED: 'warning',
  DOWN: 'disabled',
}
const ClusterNode: React.FC<ClusterNodeProps> = ({ id, name, ip, status: nodeStatus, isLeader }) => {
  const status: StatusType = statusByNodeStatus[nodeStatus]

  return (
    <Card>
      <CardContent>
        <Flex>
          {isLeader && (
            <Text sx={{ color: '#ffcc00', position: 'absolute', right: '11px', top: '11px' }}>
              <LeaderIcon />
            </Text>
          )}
          <Box sx={{ flex: '1 1 auto', pr: 1 }}>
            <Box mb={1}>
              <Text size={0} sx={{ color: 'grays.5' }} pb={1}>
                Name
              </Text>
            </Box>
            <Text size={3} sx={{ fontWeight: 700 }}>
              {name}
            </Text>
            <Box mt={1}>
              <Text size={0} sx={{ color: 'grays.5', mr: '4px' }} pb={1}>
                IP:
              </Text>
              <Text size={1} sx={{ fontWeight: 500 }}>
                {ip}
              </Text>
            </Box>
          </Box>
          <Box sx={{ flex: '1 1 auto' }}>
            <Box mb={1}>
              <Text size={0} sx={{ color: 'grays.5' }}>
                STATUS
              </Text>
            </Box>
            <Flex sx={{ alignItems: 'center' }}>
              <Status status={status} />
              <Text size={1} sx={{ color: colorByStatus[status], fontWeight: 600 }}>
                {`${nodeStatus.charAt(0).toUpperCase()}${nodeStatus.substring(1).toLowerCase()}`}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Box>
          <Text size={0} sx={{ color: 'grays.5' }}>
            Node&nbsp;ID:&nbsp;
          </Text>
          <Text size={0} sx={{ color: 'grays.5' }}>
            {id}
          </Text>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ClusterNode
