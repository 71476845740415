import React, { useEffect, useState } from 'react'
import { Box, Flex, Text, Heading as FaencyHeading, theme, Thead, Tfoot, Tr, Th, Td, Tooltip } from '@containous/faency'
import styled from 'styled-components'
import Icon from 'react-eva-icons'
import useSWR from 'swr'
import { motion } from 'framer-motion'
import Page from '../Page'
import TraefikResourceStatsCard, {
  TraefikResourceStatsCardProps,
  TraefikResourceStatsType,
} from '../TraefikResourceStatsCard'
import { AnimatedRow, AnimatedTable, AnimatedTBody } from '../AnimatedTable'
import { RenderRowType } from '../../hooks/use-fetch-with-pagination'
import { SpinnerLoader } from '../SpinnerLoader'
import { EmptyPlaceholder } from '../EmptyPlaceholder'
import { ResourceStatus } from '../ResourceStatus'
import { ChipLimited } from '../ChipLimited'
import { StatusType } from '../Status'
import { useHistory } from 'react-router-dom'

const Heading = styled(FaencyHeading)`
  align-items: center;
  display: flex;

  i {
    padding-right: 8px;
  }
`

const ChartsContainer = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 38px;
`

const CardWrapper = styled(Box)`
  display: grid;
  margin-left: -${theme.space[2]};
  margin-right: -${theme.space[2]};
  border-radius: 12px;
  overflow: hidden;
`

const AnimatedChartContainer = motion.custom(ChartsContainer)
const AnimatedCardWrapper = motion.custom(CardWrapper)
const AnimatedBox = motion.custom(Box)

type ChartBlockType = {
  title: string
  icon: string
  stats: TraefikResourceStatsType
  isMounted: boolean
  animatedRowProps: any // eslint-disable-line @typescript-eslint/no-explicit-any
  onExplore?: () => void
}

const ChartBlock: React.FC<ChartBlockType> = ({
  title,
  icon,
  stats,
  isMounted,
  animatedRowProps,
  onExplore,
  ...props
}) => (
  <AnimatedBox {...props} animate={isMounted ? 'visible' : 'hidden'} {...animatedRowProps}>
    <Heading mb={1}>
      <Icon name={icon} size="large" fill="black" /> {title}
    </Heading>
    <AnimatedCardWrapper sx={{ minHeight: 220 }}>
      {stats && <TraefikResourceStatsCard {...stats} onExplore={onExplore} />}
    </AnimatedCardWrapper>
  </AnimatedBox>
)

const renderRow: RenderRowType = ({ row }) => (
  <AnimatedRow key={`${row.status}-${row.section}-${row.name}-${row.type}`}>
    <Td style={{ width: '20%' }}>
      <ResourceStatus status={row.status} />
    </Td>
    <Td style={{ width: '20%' }}>{row.section}</Td>
    <Td style={{ width: '20%' }}>{row.type}</Td>
    <Td style={{ width: '20%' }}>
      <Tooltip label={row.name} action="copy">
        <ChipLimited variant="blue" truncate>
          {row.name}
        </ChipLimited>
      </Tooltip>
    </Td>
    <Td style={{ width: '20%' }}>
      <Text sx={{ color: row.status.toLowerCase() === 'error' ? 'red' : 'orange' }} fontWeight={600}>
        {row.message}
      </Text>
    </Td>
  </AnimatedRow>
)

type OverviewData = {
  total?: number
  warnings?: number
  errors?: number
}

type OverviewDataSum = {
  total: number
  warnings: number
  errors: number
}

const sumOverviewData = (sum: OverviewData, data: OverviewData): OverviewDataSum => {
  const initialData = {
    total: 0,
    warnings: 0,
    errors: 0,
    ...sum,
  }

  return {
    total: initialData.total + (data.total || 0),
    warnings: initialData.warnings + (data.warnings || 0),
    errors: initialData.errors + (data.errors || 0),
  }
}

type IngressOverviewData = {
  http?: {
    routers?: OverviewData
    services?: OverviewData
    middlewares?: OverviewData
  }
  tcp?: {
    routers?: OverviewData
    services?: OverviewData
  }
  udp?: {
    routers?: OverviewData
    services?: OverviewData
  }
}

const summarizeIngressOverview = (data: IngressOverviewData = {}): TraefikResourceStatsCardProps => {
  let result = {} as TraefikResourceStatsCardProps

  for (const protocol of Object.values(data)) {
    for (const resource of Object.values(protocol || {})) {
      if (resource && resource.total !== undefined) {
        result = sumOverviewData(result, resource)
      }
    }
  }

  return result
}

type ClusterOverviewData = {
  nodes?: OverviewData
}

const summarizeClusterOverview = (data: ClusterOverviewData = {}): TraefikResourceStatsCardProps => {
  let result = {} as TraefikResourceStatsCardProps

  if (data.nodes) {
    result = sumOverviewData(result, data.nodes)
  }

  return result
}

type MeshOverviewData = {
  service?: OverviewData
  acl?: OverviewData
  trafficSplit?: OverviewData
}

const summarizeMeshOverview = (data: MeshOverviewData = {}): TraefikResourceStatsCardProps => {
  let result = {} as TraefikResourceStatsCardProps

  for (const resource of Object.values(data)) {
    if (resource && resource.total !== undefined) {
      result = sumOverviewData(result, resource)
    }
  }

  return result
}

type ErrorType = {
  status: StatusType
  section: string
  type: string
  name: string
  message: string
}

const getErrorsWithSection = (errors: ErrorType[], section: string): ErrorType[] =>
  errors.map(error => ({ ...error, section }))

export const Overview: React.FC = () => {
  const [isMounted, setMounted] = useState(false)
  const { data: clusterOverview } = useSWR('/cluster/overview')
  const { data: ingressOverview } = useSWR('/overview')
  const { data: ingressErrors } = useSWR<ErrorType[]>('/errors')
  const { data: clusterErrors } = useSWR<ErrorType[]>('/cluster/errors')
  const isMeshEnabled = clusterOverview?.platform === 'Kubernetes' && clusterOverview?.mesh === true
  const { data: meshOverview } = useSWR(isMeshEnabled ? '/mesh/overview' : null)
  const { data: meshErrors } = useSWR<ErrorType[]>(isMeshEnabled ? '/mesh/errors' : null)
  const history = useHistory()
  const errors = [
    ...getErrorsWithSection(ingressErrors || [], 'Ingress'),
    ...getErrorsWithSection(clusterErrors || [], 'Cluster'),
    ...getErrorsWithSection(meshErrors || [], 'Mesh'),
  ]

  errors.sort((a, b) => {
    if (a.section !== b.section) {
      return a.section > b.section ? 1 : -1
    }

    if (a.status !== b.status) {
      return a.status > b.status ? 1 : -1
    }

    if (a.type !== b.type) {
      return a.type > b.type ? 1 : -1
    }

    if (a.name !== b.name) {
      return a.name > b.name ? 1 : -1
    }

    return a.message > b.message ? 1 : -1
  })

  const ingressStats: TraefikResourceStatsCardProps = ingressOverview && summarizeIngressOverview(ingressOverview)
  const clusterStats: TraefikResourceStatsCardProps = clusterOverview && summarizeClusterOverview(clusterOverview)
  const meshStats: TraefikResourceStatsCardProps = meshOverview && summarizeMeshOverview(meshOverview)

  useEffect(() => {
    setMounted(true)
  }, [])

  const animatedListProps = {
    variants: {
      hidden: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
      visible: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
      },
    },
  }

  const animatedRowProps = {
    variants: {
      hidden: {
        x: -6,
        opacity: 0,
      },
      visible: {
        x: 0,
        opacity: 1,
      },
    },
    initial: 'hidden',
  }

  const animationProps = {
    animatedListProps,
    animatedRowProps,
    isMounted,
  }

  return (
    <Page data-testid="overview-page">
      {(ingressOverview || clusterOverview || meshOverview) && (
        <AnimatedChartContainer mb={3} {...animatedListProps}>
          {clusterOverview && (
            <ChartBlock
              title="Cluster"
              icon="layers-outline"
              stats={clusterStats}
              data-testid="section-cluster"
              onExplore={(): void => history.push('/cluster')}
              {...animationProps}
            />
          )}
          {ingressOverview && (
            <ChartBlock
              title="Ingress"
              icon="globe-outline"
              stats={ingressStats}
              data-testid="section-ingress"
              onExplore={(): void => history.push('/ingress')}
              {...animationProps}
            />
          )}
          {meshOverview && (
            <ChartBlock
              title="Mesh"
              icon="swap-outline"
              stats={meshStats}
              data-testid="section-mesh"
              onExplore={(): void => history.push('/mesh')}
              {...animationProps}
            />
          )}
        </AnimatedChartContainer>
      )}

      <Box data-testid="overview-errors-table">
        <Heading mb={1}>
          <Icon name="alert-triangle-outline" size="large" fill="black" />
          <Text>Errors</Text>
        </Heading>
        <AnimatedTable infiniteRef={null}>
          <Thead>
            <Tr>
              <Th style={{ width: '10%' }}>Status</Th>
              <Th style={{ width: '10%' }}>Section</Th>
              <Th style={{ width: '20%' }}>Type</Th>
              <Th style={{ width: '20%' }}>Name</Th>
              <Th style={{ width: '40%' }}>Message</Th>
            </Tr>
          </Thead>
          {/* InfiniteRef and pageCount set to fixed values until we have pagination for Mesh Errors */}
          <AnimatedTBody pageCount={1} isMounted={isMounted}>
            {errors.map(row => renderRow({ row }))}
          </AnimatedTBody>
          {errors.length === 0 && (
            <Tfoot>
              <Tr>
                <td colSpan={100}>
                  <EmptyPlaceholder />
                </td>
              </Tr>
            </Tfoot>
          )}
        </AnimatedTable>
        {(!ingressErrors || !clusterErrors || (isMeshEnabled && !meshErrors)) && (
          <Flex sx={{ height: 60, alignItems: 'center', justifyContent: 'center' }}>
            <SpinnerLoader />
          </Flex>
        )}
      </Box>
    </Page>
  )
}
