import React, { useReducer, useEffect } from 'react'
import { Box, Flex, Input, Button, theme } from '@containous/faency'
import Icon from 'react-eva-icons'
import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'
import { transparentize } from 'polished'

export type State = { status?: string; search?: string }

type Action = { type: 'SET_STATUS' | 'SET_SEARCH'; value?: string }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_STATUS':
      return {
        ...state,
        status: action.value,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.value,
      }
  }
}

type StatusType = {
  id: string
  value?: string
  name: string
}

const allStatus: StatusType[] = [
  { id: 'all', value: undefined, name: 'All Status' },
  { id: 'enabled', value: 'enabled', name: 'Success' },
  { id: 'warning', value: 'warning', name: 'Warnings' },
  { id: 'disabled', value: 'disabled', name: 'Errors' },
]

const initialState: State = { status: 'all' }

type TableFilterProps = {
  initialData?: State
  onChange: (state: State) => void
}

const Status = styled(Button).attrs(() => ({
  variant: 'primary',
  marginRight: 2,
}))`
  height: 36px;
  padding: 4px 16px;
  border-radius: 12px;
  background: transparent;
  color: ${theme.colors.grays[5]};
  font-weight: 600;
  font-size: 14px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), opacity 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);

  &:hover {
    background: ${theme.colors.grays[1]};
  }

  &:active,
  &.active {
    background: ${transparentize(0.9, theme.colors.primary)};
    color: ${theme.colors.primary};
  }
`

export type TableSearchProps = TableFilterProps & {
  hideStatusFilter?: boolean
}

export const TableSearch: React.FC<TableSearchProps> = ({ initialData, onChange, hideStatusFilter }) => {
  const [state, dispatch] = useReducer(reducer, initialData || initialState)

  const [debouncedCallback] = useDebouncedCallback(async (value?: string) => {
    await dispatch({ type: 'SET_SEARCH', value: value || undefined })
  }, 500)

  useEffect(() => {
    onChange(state)
  }, [state, onChange])

  return (
    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }} mb="3">
      <Flex>
        {!hideStatusFilter &&
          allStatus.map(({ id, value, name }) => (
            <Status
              key={id}
              className={state.status === value ? 'active' : ''}
              onClick={(): void => dispatch({ type: 'SET_STATUS', value })}
            >
              {name}
            </Status>
          ))}
      </Flex>
      <Box sx={{ maxWidth: 200, position: 'relative' }}>
        <Input
          data-testid="table-search-input"
          defaultValue={state.search}
          placeholder="Search"
          onChange={(e): void => debouncedCallback(e.target.value)}
          pr={5}
        />
        <Box sx={{ position: 'absolute', height: '100%', top: 0, right: 1, color: 'grays.5', pointerEvents: 'none' }}>
          <Flex sx={{ alignItems: 'center', height: '100%' }}>
            <Icon name="search" size="large" fill={theme.colors.grays[5]} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default TableSearch
