import React, { useEffect, MutableRefObject, useState, useCallback } from 'react'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import { Flex, Thead, Tfoot, Tr, Th, Td, Tooltip } from '@containous/faency'
import { useQueryParams, StringParam } from 'use-query-params'
import qs from 'query-string'
import Page from '../../Page'
import useFetchWithPagination, { RenderRowType } from '../../../hooks/use-fetch-with-pagination'
import { parseMiddlewareType } from '../../../libs/parsers'
import TableFilter, { State as FilterState } from '../../TableFilter'
import { SpinnerLoader } from '../../SpinnerLoader'
import { ScrollTopButton } from '../../ScrollTopButton'
import { ResourceStatus } from '../../ResourceStatus'
import { ProviderIcon } from '../../ProviderIcon'
import { EmptyPlaceholder } from '../../EmptyPlaceholder'
import { useNavigator, NavigatorType } from '../../../utils/navigation'
import { ChipLimited } from '../../ChipLimited'
import { AnimatedTable, AnimatedRow, AnimatedTBody } from '../../AnimatedTable'

const makeRowRender = (navigate: NavigatorType): RenderRowType => (row): JSX.Element => {
  const middlewareType = parseMiddlewareType(row)

  return (
    <AnimatedRow key={row.name} onClick={(): void => navigate(`/ingress/http/middlewares/${row.name}`)}>
      <Td style={{ textAlign: 'center' }}>
        <Tooltip label={row.status} preferredAlignment="left">
          <ResourceStatus status={row.status} />
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={row.name} action="copy">
          <ChipLimited truncate variant="purple">
            {row.name}
          </ChipLimited>
        </Tooltip>
      </Td>
      <Td>
        {middlewareType && (
          <Tooltip label={middlewareType} action="copy">
            <ChipLimited truncate variant="blue">
              {middlewareType}
            </ChipLimited>
          </Tooltip>
        )}
      </Td>
      <Td style={{ textAlign: 'right' }}>
        <Tooltip label={row.provider} preferredAlignment="right">
          <ProviderIcon name={row.provider} />
        </Tooltip>
      </Td>
    </AnimatedRow>
  )
}

export const HttpMiddlewares: React.FC = () => {
  const navigate = useNavigator()
  const renderRow = makeRowRender(navigate)
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    status: StringParam,
  })
  const [isMounted, setMounted] = useState(false)
  const onChangeFilter = useCallback(
    (state: FilterState): void => {
      setQuery(state, 'replaceIn')
    },
    [setQuery],
  )

  const { pages, pageCount, isLoadingMore, isReachingEnd, loadMore, error, isEmpty } = useFetchWithPagination(
    '/http/middlewares',
    {
      listContextKey: JSON.stringify(query),
      renderRow,
      renderLoader: () => null,
      query,
    },
  )
  const infiniteRef: MutableRefObject<null | undefined> = useInfiniteScroll({
    loading: isLoadingMore,
    hasNextPage: !isReachingEnd && !error,
    onLoadMore: loadMore,
  })

  useEffect(() => {
    setMounted(true)
    const originalUrlParams = qs.parse(window.location.search)
    setQuery(originalUrlParams)
  }, [setQuery])

  return (
    <Page title="HTTP Middlewares">
      <TableFilter initialData={query} onChange={onChangeFilter} />

      <AnimatedTable infiniteRef={infiniteRef}>
        <Thead>
          <Tr>
            <Th style={{ width: '72px' }}>Status</Th>
            <Th style={{ width: '50%' }}>Name</Th>
            <Th style={{ width: '50%' }}>Type</Th>
            <Th style={{ textAlign: 'right', width: '80px' }}>Provider</Th>
          </Tr>
        </Thead>
        <AnimatedTBody pageCount={pageCount} isMounted={isMounted}>
          {pages}
        </AnimatedTBody>
        {(isEmpty || !!error) && (
          <Tfoot>
            <Tr>
              <td colSpan={100}>
                <EmptyPlaceholder message={error ? 'Failed to fetch data' : 'No data available'} />
              </td>
            </Tr>
          </Tfoot>
        )}
      </AnimatedTable>
      <Flex sx={{ height: 60, alignItems: 'center', justifyContent: 'center' }}>
        {isLoadingMore ? <SpinnerLoader /> : isReachingEnd && pageCount > 1 && <ScrollTopButton />}
      </Flex>
    </Page>
  )
}
