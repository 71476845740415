import React from 'react'
import { Flex, Text, Card as FaencyCard, theme } from '@containous/faency'
import { transparentize } from 'polished'
import styled from 'styled-components'

const Card = styled(FaencyCard)`
  height: 168;
  margin: ${theme.space[2]};
  padding: ${theme.space[2]};
  overflow: hidden;
`

export type FeatureCardProps = {
  name: string
  value: string | boolean
}

const FeatureCard: React.FC<FeatureCardProps> = ({ name, value }) => {
  return (
    <Card>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Text pb={2} sx={{ color: 'grays.5', fontSize: '14px', fontWeight: 600, letterSpacing: '3px' }}>
          {name.toUpperCase()}
        </Text>
        {typeof value === 'boolean' || !value ? (
          <Flex
            sx={{
              height: 56,
              bg: transparentize(0.9, value ? '#00a697' : '#ff0039'),
              borderRadius: theme.radii[4],
              alignItems: 'center',
              justifyContent: 'center',
            }}
            paddingX={3}
            paddingY={1}
          >
            <Text size={7} sx={{ color: value ? '#00a697' : '#ff0039', fontWeight: 700 }}>
              {value ? 'ON' : 'OFF'}
            </Text>
          </Flex>
        ) : (
          <Flex
            sx={{
              height: 56,
              bg: transparentize(0.9, '#737373'),
              borderRadius: theme.radii[4],
              alignItems: 'center',
              justifyContent: 'center',
            }}
            paddingX={3}
            paddingY={1}
          >
            <Text sx={{ color: '#737373', fontWeight: 700 }} size={4}>
              {value}
            </Text>
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

export default FeatureCard
