import React from 'react'
import { MiddlewareProps } from '../hooks/use-resource-detail'
import { Box, Tooltip, Text, theme } from '@containous/faency'
import { ChipLimited } from './ChipLimited'

function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function quote(value: any): any {
  if (typeof value === 'string') {
    return `"${value}"`
  }

  return value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function quoteArray(values: any[]): any {
  return values.map(quote)
}

type JSONObject = {
  [x: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
type ValuesMapType = {
  [key: string]: string | number | JSONObject
}
const renderFeatureValues = (valuesMap: ValuesMapType): string => {
  return Object.entries(valuesMap)
    .map(([name, value]) => {
      const capitalizedName = capitalize(name)
      if (typeof value === 'string') {
        return [capitalizedName, `"${value}"`].join('=')
      }

      if (value instanceof Array) {
        return [capitalizedName, quoteArray(value).join(', ')].join('=')
      }

      if (typeof value === 'object') {
        return [capitalizedName, `{${renderFeatureValues(value)}}`].join('=')
      }

      return [capitalizedName, value].join('=')
    })
    .join(', ')
}

const FeatureMiddleware: React.FC<{ middleware: MiddlewareProps }> = ({ middleware }) => {
  const [name, value] = Object.entries(middleware)[0]
  const content = `${capitalize(name)}: ${renderFeatureValues(value)}`

  return (
    <Tooltip label={content} action="copy">
      <ChipLimited variant="blue" sx={{ mr: 1, mt: 1 }} truncate>
        {content}
      </ChipLimited>
    </Tooltip>
  )
}

type AdditionalFeaturesProps = {
  middlewares?: MiddlewareProps[]
  uid: string
}

const AdditionalFeatures: React.FC<AdditionalFeaturesProps> = ({ middlewares, uid }) => {
  return middlewares?.length ? (
    <Box sx={{ mt: -1 }}>
      {middlewares.map((m, idx) => (
        <FeatureMiddleware key={`${uid}-${idx}`} middleware={m} />
      ))}
    </Box>
  ) : (
    <Text sx={{ fontStyle: 'italic', color: theme.colors.grays[5] }}>No additional features</Text>
  )
}

export default AdditionalFeatures
