import React, { useEffect, useState } from 'react'
import { Box, Text, Heading as FaencyHeading, theme } from '@containous/faency'
import styled from 'styled-components'
import Icon from 'react-eva-icons'
import useSWR from 'swr'
import { motion } from 'framer-motion'
import Page from '../Page'
import ClusterNodeCard from '../ClusterNodeCard'
import { StatusDotsNodes } from '../StatusDots'

const Heading = styled(FaencyHeading)`
  align-items: center;
  display: flex;

  i {
    padding-right: 8px;
  }
`

const CardWrapper = styled(Box)`
  display: grid;
  margin-left: -${theme.space[2]};
  margin-right: -${theme.space[2]};
  border-radius: 12px;
  overflow: hidden;
`

type ClusterNode = {
  id: string
  name: string
  role: string
  ip: string
  status: 'UNKNOWN' | 'READY' | 'DISCONNECTED' | 'DOWN'
  isLeader?: boolean
}

type Ingress = ClusterNode & {
  role: 'Proxy / Ingress' | 'Proxy / Mesh'
}

type Controller = ClusterNode & {
  role: 'Controller'
}

type PluginRegistry = ClusterNode & {
  role: 'Plugin Registry'
}

export const Cluster: React.FC = () => {
  const [isMounted, setMounted] = useState(false)
  const { data: clusterNodes } = useSWR('/cluster/nodes')

  const controllers: Controller[] = clusterNodes?.filter((node: ClusterNode) => node.role === 'Controller')
  const ingresses: Ingress[] = clusterNodes?.filter((node: ClusterNode) => node.role === 'Proxy / Ingress')
  const mesh: Ingress[] = clusterNodes?.filter((node: ClusterNode) => node.role === 'Proxy / Mesh')
  const pluginRegistry: PluginRegistry[] = clusterNodes?.filter((node: ClusterNode) => node.role === 'Plugin Registry')

  useEffect(() => {
    setMounted(true)
  }, [])

  const animatedListProps = {
    variants: {
      hidden: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
      visible: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
      },
    },
  }

  const animatedRowProps = {
    variants: {
      hidden: {
        x: -6,
        opacity: 0,
      },
      visible: {
        x: 0,
        opacity: 1,
      },
    },
    initial: 'hidden',
  }

  const AnimatedCardWrapper = motion.custom(CardWrapper)

  return (
    <Page data-testid="overview-page">
      {controllers?.length > 0 && (
        <Box mb={3}>
          <Heading mb={1} data-testid="controllers-title">
            <Icon name="options-2-outline" size="large" fill="black" />
            <Text mr={2}>Controllers</Text>
            <StatusDotsNodes nodes={controllers} />
          </Heading>
          <AnimatedCardWrapper
            sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', minHeight: 144 }}
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {controllers.map(controller => (
              <motion.div {...animatedRowProps} key={controller.name}>
                <ClusterNodeCard
                  id={controller.id}
                  name={controller.name}
                  ip={controller.ip}
                  status={controller.status}
                  isLeader={controller.isLeader as boolean}
                />
              </motion.div>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
      {ingresses?.length > 0 && (
        <Box mb={3}>
          <Heading mb={1} data-testid="ingresses-title">
            <Icon name="layers-outline" size="large" fill="black" />
            <Text mr={2}>Proxies / Ingresses</Text>
            <StatusDotsNodes nodes={ingresses} />
          </Heading>
          <AnimatedCardWrapper
            sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', minHeight: 144 }}
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {ingresses.map(ingress => (
              <motion.div {...animatedRowProps} key={ingress.name}>
                <ClusterNodeCard
                  id={ingress.id}
                  name={ingress.name}
                  ip={ingress.ip}
                  status={ingress.status}
                  isLeader={ingress.isLeader as boolean}
                />
              </motion.div>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
      {mesh?.length > 0 && (
        <Box mb={3}>
          <Heading mb={1}>
            <Icon name="swap-outline" size="large" fill="black" />
            <Text mr={2}>Proxies / Mesh</Text>
            <StatusDotsNodes nodes={mesh} />
          </Heading>
          <AnimatedCardWrapper
            sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', minHeight: 144 }}
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {mesh.map(proxy => (
              <motion.div {...animatedRowProps} key={proxy.name}>
                <ClusterNodeCard
                  id={proxy.id}
                  name={proxy.name}
                  ip={proxy.ip}
                  status={proxy.status}
                  isLeader={proxy.isLeader as boolean}
                />
              </motion.div>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
      {pluginRegistry?.length > 0 && (
        <Box mb={3}>
          <Heading mb={1}>
            <Icon name="code-download-outline" size="large" fill="black" />
            <Text mr={2}>Plugin Registries</Text>
            <StatusDotsNodes nodes={pluginRegistry} />
          </Heading>
          <AnimatedCardWrapper
            sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', minHeight: 144 }}
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {pluginRegistry.map(proxy => (
              <motion.div {...animatedRowProps} key={proxy.name}>
                <ClusterNodeCard
                  id={proxy.id}
                  name={proxy.name}
                  ip={proxy.ip}
                  status={proxy.status}
                  isLeader={proxy.isLeader as boolean}
                />
              </motion.div>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
    </Page>
  )
}
