import React from 'react'
import { Flex } from '@containous/faency'
import IframeResizer from 'iframe-resizer-react'
import qs from 'query-string'

type PlatformAuthStateProps = {
  setPlatformPath: (path: string | null) => void
  setPlatformParam: (path: string | undefined) => void
  authRedirectUrl: string
  instanceInfos: string
}

export const PlatformAuthState: React.FC<PlatformAuthStateProps> = ({
  setPlatformParam,
  setPlatformPath,
  authRedirectUrl,
  instanceInfos,
}) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const authStateUrl = qs.stringifyUrl({
    url: `${process.env.REACT_APP_PILOT_URL}/partials/auth-state`,
    query: { authRedirectUrl, instanceInfos },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePilotMessages = ({ message }: { message: any }): void => {
    if (typeof message === 'string') {
      // 1st condition for backward compatibility
      if (message === 'open:profile') {
        setPlatformPath('/')
      } else if (message.includes('open:')) {
        setPlatformPath(message.split('open:')[1])
      } else if (message === 'logout') {
        setPlatformPath(null)
        setPlatformParam(undefined)
      }
    }

    if (typeof message?.isAuthenticated === 'boolean') {
      setIsAuthenticated(message?.isAuthenticated)
    }
  }

  return (
    <Flex sx={{ height: '64px', mr: isAuthenticated ? 0 : 2 }}>
      <IframeResizer
        onMessage={handlePilotMessages}
        src={authStateUrl}
        style={{ width: 'auto', height: '64px', maxWidth: '300px', border: 'none' }}
      />
    </Flex>
  )
}
