import React, { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { Table, Tbody, Tr } from '@containous/faency'
import ClickableRow from './ClickableRow'

const tableVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

const bodyVariants = {
  hidden: {
    x: -6,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { staggerChildren: 0.03, delayChildren: 0.1 },
  },
}

const rowVariants = {
  hidden: {
    x: -6,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
}

const CustomTable = motion.custom(Table)
const CustomBody = motion.custom(Tbody)
const CustomClickableRow = motion.custom(ClickableRow)
const CustomRow = motion.custom(Tr)

type AnimatedTableProps = {
  infiniteRef: any // eslint-disable-line @typescript-eslint/no-explicit-any
  children: ReactNode
  isMounted?: boolean
}

export const AnimatedTable: React.FC<AnimatedTableProps> = ({ infiniteRef, isMounted = true, children }) => (
  <CustomTable
    style={{ tableLayout: 'fixed' }}
    ref={infiniteRef}
    initial="hidden"
    animate={isMounted ? 'visible' : 'hidden'}
    variants={tableVariants}
  >
    {children}
  </CustomTable>
)

type AnimatedTBodyProps = {
  pageCount: number
  isMounted: boolean
  children: ReactNode | null
}

export const AnimatedTBody: React.FC<AnimatedTBodyProps> = ({ pageCount, isMounted = true, children }): JSX.Element => (
  <CustomBody variants={bodyVariants} animate={isMounted && pageCount > 0 ? 'visible' : 'hidden'}>
    {children}
  </CustomBody>
)

type AnimatedRowType = {
  children: ReactNode
  onClick?: () => void
}

export const AnimatedRow: React.FC<AnimatedRowType> = ({ children, onClick }) => {
  if (onClick) {
    return (
      <CustomClickableRow onClick={onClick} variants={rowVariants}>
        {children}
      </CustomClickableRow>
    )
  }

  return <CustomRow variants={rowVariants}>{children}</CustomRow>
}
