import breakpoints from '../utils/breakpoints'
import styled from 'styled-components'
import { Box } from '@containous/faency'

export const containerStyle = `
margin: 0 12px;

@media (min-width: ${breakpoints.laptop}) {
  margin: 0 24px;
}

@media (min-width: ${breakpoints.laptopL}) {
  max-width: ${breakpoints.laptopL};
  margin: 0 auto;
}
`

export default styled(Box)`
  ${containerStyle}
`
