import { Chip, Tooltip } from '@containous/faency'
import React from 'react'
import { BooleanState, Chips, IpStrategyBlock, ItemBlock } from './DetailSections'

type RenderUnknownPropProps = {
  name: string
  prop?: unknown
  removeTitlePrefix?: string
}

export const RenderUnknownProp: React.FC<RenderUnknownPropProps> = ({ name, prop, removeTitlePrefix }) => {
  const wrap = (children: JSX.Element, altName?: string): JSX.Element => (
    <ItemBlock title={altName || name}>{children}</ItemBlock>
  )
  try {
    if (typeof prop !== undefined) {
      if (typeof prop === 'boolean') {
        return wrap(<BooleanState enabled={prop} />)
      }

      if (typeof prop === 'string' && ['true', 'false'].includes((prop as string).toLowerCase())) {
        return wrap(<BooleanState enabled={prop === 'true'} />)
      }

      if (['string', 'number'].includes(typeof prop)) {
        return wrap(
          <Tooltip label={prop as string} action="copy">
            <Chip variant="lightBlue" truncate>
              {prop as string}
            </Chip>
          </Tooltip>,
        )
      }

      if (JSON.stringify(prop) === '{}') {
        return wrap(<BooleanState enabled />)
      }

      if (prop instanceof Array) {
        return wrap(<Chips items={prop.map(p => JSON.stringify(p))} alignment="center" />)
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((prop as any)?.constructor === Object) {
        return (
          <>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {Object.entries(prop as any).map(([childName, childProp]) => {
              const spacedChildName = childName.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
              let title = `${name} > ${spacedChildName}`
              if (removeTitlePrefix) {
                title = title.replace(new RegExp(`^${removeTitlePrefix} > `, 'i'), '')
              }

              switch (childName) {
                case 'ipStrategy':
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return wrap(<IpStrategyBlock key={title} ipStrategy={childProp as any} />, title)
                default:
                  return <RenderUnknownProp key={title} name={title} prop={childProp} />
              }
            })}
          </>
        )
      }
    }
  } catch (error) {
    console.log('Unable to render plugin property:', { name, prop }, { error })
  }

  return null
}
