import React from 'react'
import Icon from 'react-eva-icons'
import { Flex, Text, theme } from '@containous/faency'

export const EmptyPlaceholder: React.FC<{ message?: string }> = ({ message = 'No data available' }) => (
  <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
    <Icon name="alert-triangle-outline" fill={theme.colors.primary} size="medium" />
    <Text pl={1}>{message}</Text>
  </Flex>
)
