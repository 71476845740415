import React from 'react'
import styled from 'styled-components'
import { Flex, Box, Chip, Heading, theme } from '@containous/faency'
import { DetailSection, ChipStack, ItemBlock, EmptyPlaceholder, ProviderName, LayoutTwoCols } from './DetailSections'
import { RouterDetailType, Middleware } from '../hooks/use-resource-detail'
import { ResourceStatus } from './ResourceStatus'
import { ProviderIcon } from './ProviderIcon'
import { parseMiddlewareType } from '../libs/parsers'
import { RenderUnknownProp } from './RenderUnknownProp'

const Separator = styled('hr')`
  border: none;
  background: ${theme.colors.blacks[4]};
  margin: 0 0 ${theme.space[3]};
  height: 1px;
  min-height: 1px;
`

const filterMiddlewareProps = (middleware: Middleware): string[] => {
  const filteredProps = [] as string[]
  const propsToRemove = ['name', 'plugin', 'status', 'type', 'provider', 'error', 'usedBy', 'routers']

  Object.keys(middleware).map(propName => {
    if (!propsToRemove.includes(propName)) {
      filteredProps.push(propName)
    }
  })

  return filteredProps
}

export const RenderMiddleware: React.FC<{ middleware: Middleware; withHeader?: boolean }> = ({
  middleware,
  withHeader,
}) => (
  <Flex key={middleware.name} sx={{ flexDirection: 'column' }}>
    {withHeader && <Heading mb={3}>{middleware.name}</Heading>}
    <LayoutTwoCols>
      {(middleware.type || middleware.plugin) && (
        <ItemBlock title="Type">
          <Chip variant="purple" truncate>
            {parseMiddlewareType(middleware)}
          </Chip>
        </ItemBlock>
      )}
      {middleware.provider && (
        <ItemBlock title="Provider">
          <ProviderIcon name={middleware.provider} />
          <ProviderName ml={1}>{middleware.provider}</ProviderName>
        </ItemBlock>
      )}
    </LayoutTwoCols>
    {middleware.status && (
      <ItemBlock title="Status">
        <ResourceStatus status={middleware.status} withLabel />
      </ItemBlock>
    )}
    {middleware.error && (
      <ItemBlock title="Errors">
        <ChipStack items={middleware.error} variant="red" />
      </ItemBlock>
    )}
    {middleware.plugin &&
      Object.keys(middleware.plugin).map(pluginName => (
        <RenderUnknownProp key={pluginName} name={pluginName} prop={middleware.plugin[pluginName]} />
      ))}
    {filterMiddlewareProps(middleware).map(propName => (
      <RenderUnknownProp
        key={propName}
        name={propName}
        prop={middleware[propName]}
        removeTitlePrefix={middleware.type}
      />
    ))}
  </Flex>
)

const MiddlewarePanel: React.FC<{ data: RouterDetailType }> = ({ data }) => (
  <DetailSection icon="layers" title="Middlewares">
    {data.middlewares ? (
      data.middlewares.map((middleware, index) => (
        <Box key={middleware.name}>
          <RenderMiddleware middleware={middleware} withHeader />
          {data.middlewares && index < data.middlewares.length - 1 && <Separator />}
        </Box>
      ))
    ) : (
      <Flex sx={{ flexDirection: 'column', flexGrow: 1, textAlign: 'center', justifyContent: 'center' }}>
        <img src="img/middlewares-empty.svg" alt="" />
        <EmptyPlaceholder mt={2}>
          There are no
          <br />
          Middlewares configured
        </EmptyPlaceholder>
      </Flex>
    )}
  </DetailSection>
)

export default MiddlewarePanel
