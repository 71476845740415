import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Box, theme, Flex, Table, Thead, Tbody, Tr, Th, Td } from '@containous/faency'
import { SectionHeader } from './DetailSections'
import { useNavigator } from '../utils/navigation'
import { makeRowRender } from './pages/ingress/HttpRouters'
import { ToastContext } from '../contexts/toasts'
import { ServiceDetailType, MiddlewareDetailType } from '../hooks/use-resource-detail'

type UsedByRoutersSectionProps = {
  data: ServiceDetailType | MiddlewareDetailType
  protocol?: string
}

const SkeletonContent = styled(Box)`
  background-color: ${theme.colors.grays[1]};
  height: ${theme.fontSizes[1]};
  min-width: 50px;
  border-radius: 4px;
  margin: ${theme.space[1]};
`

export const UsedByRoutersSkeleton: React.FC = () => (
  <Flex mt={5} sx={{ flexDirection: 'column' }}>
    <SectionHeader />
    <Table>
      <Thead>
        <Tr>
          <Th>
            <SkeletonContent />
          </Th>
          <Th>
            <SkeletonContent />
          </Th>
          <Th>
            <SkeletonContent />
          </Th>
          <Th>
            <SkeletonContent />
          </Th>
          <Th>
            <SkeletonContent />
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr style={{ pointerEvents: 'none' }}>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
        </Tr>
        <Tr style={{ pointerEvents: 'none' }}>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
          <Td>
            <SkeletonContent />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Flex>
)

export const UsedByRoutersSection: React.FC<UsedByRoutersSectionProps> = ({ data, protocol = 'http' }) => {
  const navigate = useNavigator()
  const renderRow = makeRowRender(navigate, protocol)
  const { addToast } = useContext(ToastContext)

  const routersFound = useMemo(() => data.routers?.filter(r => !r.message), [data])
  const routersNotFound = useMemo(() => data.routers?.filter(r => !!r.message), [data])

  useEffect(() => {
    routersNotFound?.map(error =>
      addToast({
        message: error.message,
        severity: 'error',
      }),
    )
  }, [addToast, routersNotFound])

  if (!routersFound || routersFound.length <= 0) {
    return null
  }

  return (
    <Flex mt={3} sx={{ flexDirection: 'column' }}>
      <SectionHeader title="Used by Routers" />

      <Box sx={{ maxWidth: '100%', width: '100%', overflow: 'auto' }}>
        <Table data-testid="routers-table">
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>Entrypoints</Th>
              <Th>Name</Th>
              <Th>Service</Th>
              <Th style={{ textAlign: 'right' }}>Provider</Th>
            </Tr>
          </Thead>
          <Tbody>{routersFound.map(renderRow)}</Tbody>
        </Table>
      </Box>
    </Flex>
  )
}
