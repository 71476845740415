import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Button, Flex, Text, theme } from '@containous/faency'
import Icon from 'react-eva-icons'

export type NotificationProps = {
  message?: string
  severity?: 'error' | 'warning' | 'info'
  isVisible: boolean
  setVisibility?: (visibility: boolean) => void
}

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
`

const Notification: React.FC<NotificationProps> = ({ message, severity = 'info', isVisible, setVisibility }) => {
  const colorBySeverity = {
    error: theme.colors.negative,
    warning: theme.colors.warning,
    info: theme.colors.blue[3],
  }

  if (!isVisible) {
    return null
  }

  return (
    <Flex
      sx={{ bg: transparentize(0.9, colorBySeverity[severity]), borderRadius: 3, position: 'relative' }}
      mb={3}
      padding={3}
    >
      <Text sx={{ color: colorBySeverity[severity], fontWeight: 600 }}>{message}</Text>
      {setVisibility && (
        <CloseButton onClick={(): void => setVisibility(false)}>
          <Icon name="close-outline" fill={colorBySeverity[severity]} size="large" />
        </CloseButton>
      )}
    </Flex>
  )
}

export default Notification
