import { useContext } from 'react'
import useSWR from 'swr'
import { DateTime } from 'luxon'
import { NotificationProps } from '../components/Notification'
import { NotificationContext } from '../contexts/notifications'

const MAX_DAYS_SHOW_LICENSE_MESSAGE = 60

const useNotifications = (url?: string): NotificationProps | null => {
  const { data, error } = useSWR(url || '/cluster/notification')
  const { isVisible, setVisibility } = useContext(NotificationContext)

  if (error) {
    return { message: 'Unable to retrieve license information.', severity: 'error', isVisible, setVisibility }
  }

  const expirationDate = data?.expiration_date
  const serverDate = data?.server_date

  if (!expirationDate || !serverDate) {
    return null
  }

  const expirationDateTime = DateTime.fromISO(expirationDate)
  const serverDateTime = DateTime.fromISO(serverDate)

  const diffDays = expirationDateTime.diff(serverDateTime).as('days')
  const humanDiffDays = expirationDateTime.toRelative({ base: serverDateTime, round: true, locale: 'en', unit: 'days' })
  const humanDiff = expirationDateTime.toRelative({ base: serverDateTime, round: true, locale: 'en' })
  const diff = diffDays > 30 ? humanDiffDays : humanDiff

  if (diffDays <= MAX_DAYS_SHOW_LICENSE_MESSAGE) {
    if (expirationDate > serverDate) {
      return {
        message: `Your license will expire ${diff}`,
        severity: 'warning',
        isVisible,
        setVisibility,
      }
    } else {
      return { message: `Your license has expired ${diff}`, severity: 'error', isVisible, setVisibility }
    }
  }

  return { isVisible: false }
}

export default useNotifications
