/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ConfigInterface, useSWRInfinite } from 'swr'
import qs from 'query-string'
import { fetchPage } from '../libs/fetch'

export type RenderRowType = (row: any) => JSX.Element

type pagesResponseInterface = {
  pages: JSX.Element | JSX.Element[] | null
  pageCount: number
  error: any
  isLoadingMore: boolean
  isReachingEnd: boolean
  isEmpty: boolean
  loadMore: () => void
}
type useFetchWithPaginationType = (
  path: string,
  opts: ConfigInterface & {
    rowsPerPage?: number
    renderRow: RenderRowType
    renderLoader?: () => JSX.Element | null
    listContextKey?: string
    query?: any
  },
) => pagesResponseInterface

const useFetchWithPagination: useFetchWithPaginationType = (path, opts) => {
  const defaultLoadingFunction = (): JSX.Element => (
    <tr>
      <td>Loading...</td>
    </tr>
  )
  const { rowsPerPage = 10, renderLoader = defaultLoadingFunction, renderRow, query } = opts

  const getKey = (pageIndex: number, previousPageData: any): string | null => {
    if (previousPageData && (!previousPageData.data?.length || previousPageData.nextPage === 1)) return null

    return `${path}?${qs.stringify({
      page: pageIndex + 1,
      per_page: rowsPerPage, // eslint-disable-line @typescript-eslint/camelcase
      ...query,
    })}`
  }

  const { data: res, error, size, setSize } = useSWRInfinite(getKey, fetchPage)

  const isLoadingInitialData = !res && !error
  const isEmpty = !res?.[0]?.data || res?.[0]?.data?.length === 0
  const isLoadingMore = isLoadingInitialData || (size > 0 && res && typeof res[size - 1] === 'undefined') || false
  const nextPage = res?.[size - 1]?.nextPage
  const isReachingEnd = !nextPage || nextPage === 1

  const loadMore = (): void => {
    if (!isLoadingMore) {
      setSize(size + 1)
    }
  }

  const data = res?.reduce((acc, req) => {
    if (req.data) {
      acc.push(...req.data)
    }
    return acc
  }, [] as any[])

  let pages = null

  if (!error) {
    pages = !data ? renderLoader() : data?.map(renderRow)
  }

  return {
    pages,
    pageCount: size,
    isEmpty,
    error,
    isLoadingMore,
    isReachingEnd,
    loadMore,
  }
}

export default useFetchWithPagination
