import React from 'react'
import styled, { SimpleInterpolation } from 'styled-components'

type ProviderIconPropsType = {
  name: string
  size?: number
}

export const ProviderIcon = styled.img.attrs((props: ProviderIconPropsType) => ({
  src: `img/providers/${props.name?.toLowerCase()}.${props.name?.toLowerCase() === 'ecs' ? 'png' : 'svg'}`,
}))`
  ${(props: ProviderIconPropsType): SimpleInterpolation => `
    width: ${props.size || 32}px;
    height: ${props.size || 32}px;
    border-radius: ${props.name?.toLowerCase() === 'traefikee' ? '0' : '50%'};
  `}
` as React.FC<ProviderIconPropsType>
