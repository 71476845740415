import React, { useEffect, MutableRefObject, useState, useCallback } from 'react'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import { Flex, Thead, Tfoot, Tr, Th, Td, Tooltip } from '@containous/faency'
import { useQueryParams, StringParam } from 'use-query-params'
import qs from 'query-string'
import useFetchWithPagination, { RenderRowType } from '../../../hooks/use-fetch-with-pagination'
import { useNavigator, NavigatorType } from '../../../utils/navigation'
import Page from '../../Page'
import TableFilter, { State as FilterState } from '../../TableFilter'
import { SpinnerLoader } from '../../SpinnerLoader'
import { ScrollTopButton } from '../../ScrollTopButton'
import { ResourceStatus } from '../../ResourceStatus'
import { ProviderIcon } from '../../ProviderIcon'
import { EmptyPlaceholder } from '../../EmptyPlaceholder'
import { ChipLimited } from '../../ChipLimited'
import { Chips } from '../../DetailSections'
import { AnimatedTable, AnimatedRow, AnimatedTBody } from '../../AnimatedTable'

export const makeRowRender = (navigate: NavigatorType): RenderRowType => (row): JSX.Element => (
  <AnimatedRow key={row.name} onClick={(): void => navigate(`/ingress/udp/routers/${row.name}`)}>
    <Td style={{ textAlign: 'center' }}>
      <Tooltip label={row.status} preferredAlignment="left">
        <ResourceStatus status={row.status} />
      </Tooltip>
    </Td>
    <Td>{row.entryPoints && row.entryPoints.length > 0 && <Chips items={row.entryPoints} />}</Td>
    <Td>
      <Tooltip label={row.name} action="copy">
        <ChipLimited truncate variant="purple">
          {row.name}
        </ChipLimited>
      </Tooltip>
    </Td>
    <Td>
      <Tooltip label={row.service} action="copy">
        <ChipLimited truncate variant="orange">
          {row.service}
        </ChipLimited>
      </Tooltip>
    </Td>
    <Td style={{ textAlign: 'right' }}>
      <Tooltip label={row.provider}>
        <ProviderIcon name={row.provider} />
      </Tooltip>
    </Td>
  </AnimatedRow>
)

export const UdpRouters: React.FC = () => {
  const navigate = useNavigator()
  const renderRow = makeRowRender(navigate)
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    status: StringParam,
  })
  const [isMounted, setMounted] = useState(false)
  const onChangeFilter = useCallback(
    (state: FilterState): void => {
      setQuery(state, 'replaceIn')
    },
    [setQuery],
  )

  const { pages, pageCount, isLoadingMore, isReachingEnd, loadMore, error, isEmpty } = useFetchWithPagination(
    '/udp/routers',
    {
      listContextKey: JSON.stringify(query),
      renderRow,
      renderLoader: () => null,
      query,
    },
  )
  const infiniteRef: MutableRefObject<null | undefined> = useInfiniteScroll({
    loading: isLoadingMore,
    hasNextPage: !isReachingEnd && !error,
    onLoadMore: loadMore,
  })

  useEffect(() => {
    setMounted(true)
    const originalUrlParams = qs.parse(window.location.search)
    setQuery(originalUrlParams)
  }, [setQuery])

  return (
    <Page title="UDP Routers">
      <TableFilter initialData={query} onChange={onChangeFilter} />

      <AnimatedTable infiniteRef={infiniteRef}>
        <Thead>
          <Tr>
            <Th style={{ width: '72px' }}>Status</Th>
            <Th style={{ width: '33%' }}>Entrypoints</Th>
            <Th style={{ width: '33%' }}>Name</Th>
            <Th style={{ width: '33%' }}>Service</Th>
            <Th style={{ textAlign: 'right', width: '80px' }}>Provider</Th>
          </Tr>
        </Thead>
        <AnimatedTBody pageCount={pageCount} isMounted={isMounted}>
          {pages}
        </AnimatedTBody>
        {(isEmpty || !!error) && (
          <Tfoot>
            <Tr>
              <td colSpan={100}>
                <EmptyPlaceholder message={error ? 'Failed to fetch data' : 'No data available'} />
              </td>
            </Tr>
          </Tfoot>
        )}
      </AnimatedTable>
      <Flex sx={{ height: 60, alignItems: 'center', justifyContent: 'center' }}>
        {isLoadingMore ? <SpinnerLoader /> : isReachingEnd && pageCount > 1 && <ScrollTopButton />}
      </Flex>
    </Page>
  )
}
