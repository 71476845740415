import React from 'react'
import { Heading, Box, Card, theme } from '@containous/faency'
import useRouter from 'use-react-router'
import Page from '../../Page'
import { DetailSectionSkeleton } from '../../DetailSections'
import styled from 'styled-components'
import breakpoints from '../../../utils/breakpoints'
import { useResourceDetail } from '../../../hooks/use-resource-detail'
import { RenderMiddleware } from '../../MiddlewarePanel'
import { UsedByRoutersSection, UsedByRoutersSkeleton } from '../../UsedByRoutersSection'
import { NotFound } from '../NotFound'

const ErrorMessage = styled.div`
  color: ${theme.colors.grays[5]};
`

const MiddlewareGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`

export const HttpMiddleware: React.FC = () => {
  const {
    match: {
      params: { name: middleware },
    },
  } = useRouter<{ name: string }>()
  const { data: detail, error: detailError } = useResourceDetail(middleware, 'middlewares')

  if (detailError) {
    return (
      <Page title={middleware}>
        <ErrorMessage>
          Sorry, we could not fetch detail information for this Middleware right now. Please, try again later.
        </ErrorMessage>
      </Page>
    )
  }

  if (!detail) {
    return (
      <Page title={middleware}>
        <MiddlewareGrid>
          <DetailSectionSkeleton />
        </MiddlewareGrid>
        <UsedByRoutersSkeleton />
      </Page>
    )
  }

  if (!detail.name) {
    return <NotFound />
  }

  return (
    <Page title={middleware}>
      <Heading size={3} mb={3} mt={2}>
        {detail.name}
      </Heading>
      <MiddlewareGrid>
        <Card padding={3} data-testid="middleware-card">
          <RenderMiddleware middleware={detail} />
        </Card>
      </MiddlewareGrid>
      <UsedByRoutersSection data-testid="routers-table" data={detail} protocol="http" />
    </Page>
  )
}
