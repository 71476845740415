import React from 'react'
import {
  Flex,
  Chip,
  Menu,
  MenuItem,
  Nav,
  NavContainer as FaencyNavContainer,
  NavItem as FaencyNavItem,
  NavGroups,
  NavGroup as FaencyNavGroup,
  SubNav as FaencySubNav,
  SubNavItem,
  Text,
  theme,
  Tooltip,
} from '@containous/faency'
import { AnimatePresence } from 'framer-motion'
import { useHistory, matchPath, match } from 'react-router'
import styled, { createGlobalStyle, SimpleInterpolation } from 'styled-components'
import useSWR from 'swr'
import Icon from 'react-eva-icons'
import { useCallbackRef } from 'use-callback-ref'
import { SvgIcon } from '@material-ui/core'
import Container from './Container'
import Logo from './Logo'
import { useDebouncedCallback } from 'use-debounce'
import { makeNavigator } from '../utils/navigation'
import useTotals from '../hooks/use-overview-totals'
import routes, { NavRouteType } from '../routes'
import exists from '../utils/exists'
import breakpoints from '../utils/breakpoints'
import useClusterInfo from '../hooks/use-cluster-info'
import { StringParam, useQueryParam } from 'use-query-params'
import { PlatformPanel } from './PlatformPanel'
import { PlatformAuthState } from './PlatformAuthState'

const NavContainer = styled(FaencyNavContainer)`
  display: flex;
  flex-grow: 1;

  margin: 0;

  @media (min-width: calc(${breakpoints.laptop} + 100px)) {
    margin: 0 24px;
  }

  @media (min-width: ${breakpoints.laptopL}) {
    max-width: ${breakpoints.laptopL};
    margin: 0 auto;
  }
`

const NavGroup = styled(FaencyNavGroup)`
  flex-wrap: nowrap;
  flex-basis: auto;

  @media (min-width: ${breakpoints.tablet}) {
    flex-basis: 220px;
  }

  @media (min-width: calc(${breakpoints.laptop} + 150px)) {
    flex-basis: auto;
  }
`

const NavItem = styled(FaencyNavItem)<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isDisabled }): SimpleInterpolation =>
    isDisabled &&
    `
    &:hover {
      cursor: not-allowed;
      background: initial;
    }
  `}

  & > i {
    padding-right: 10px;
  }
`

const SubNav = styled(FaencySubNav)`
  margin-bottom: 1px;
`

const SubNavContainer = styled(Container)`
  display: flex;
  flex-grow: 1;
`

const TextHideOnTablet = styled(Text)`
  color: inherit;

  @media (min-width: ${breakpoints.tablet}) and (max-width: 1230px) {
    display: none;
  }
`

const IconContainer = styled(Flex)`
  margin-right: ${theme.space[1]};

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.laptop}) {
    margin-right: 0;
  }
`

const VersionText = styled(Text)`
  color: inherit;
  padding-left: 8px;

  @media (min-width: ${breakpoints.tablet}) and (max-width: 840px) {
    display: none;
  }
`

type NavBarItemProps = NavRouteType & { isActive: boolean; isDisabled?: boolean }

const NavBarItem: React.FC<NavBarItemProps> = ({ path, label, icon, isActive, isDisabled = false }) => {
  const history = useHistory()
  const navigate = makeNavigator(history)

  return (
    <NavItem
      variant={isActive ? 'active' : 'normal'}
      onClick={(): false | void => !isDisabled && navigate(path)}
      isDisabled={isDisabled}
    >
      <IconContainer>
        <Text sx={{ color: isActive ? 'white' : theme.colors.grays[5] }}>
          {!!icon && (
            <>
              {typeof icon === 'string' ? (
                <Icon name={icon} size="large" fill="currentColor" />
              ) : (
                <SvgIcon>{icon}</SvgIcon>
              )}
            </>
          )}
        </Text>
      </IconContainer>
      <TextHideOnTablet>{label}</TextHideOnTablet>
    </NavItem>
  )
}

// HACK: Menu component is adding inline styles to the body avoiding to use onMouseEnter event correctly
// Side effect: Menu component is now stateless, DropdownMenu is not working anymore (stateful component)
const GlobalStyle = createGlobalStyle`
  body {
    pointer-events: auto !important;
    overflow: auto !important;
    padding: 0 !important;
  }
`

const Header: React.FC = () => {
  const { data: versionData } = useSWR('/version')
  const clusterInfo = useClusterInfo()
  const history = useHistory()
  const navigate = makeNavigator(history)
  const [openMenu, setOpenMenu] = React.useState<string | null>(null)
  const [openMoreMenu, setOpenMoreMenu] = React.useState<boolean>(false)
  const filteredRoutes = routes.filter(r => !(clusterInfo?.platform !== 'Kubernetes' && r.path === '/mesh'))
  const [platformParam, setPlatformParam] = useQueryParam('platform', StringParam)
  const [platformPath, setPlatformPath] = React.useState<string | null>(platformParam || null)
  const instanceInfos = JSON.stringify(versionData)
  const authRedirectUrl = `${window.location.href.split('?')[0]}?platform=${platformParam || '/'}`

  const [setOpenMenuDebounce] = useDebouncedCallback(async (value: string | null) => {
    setOpenMenu(value)
  }, 100)

  const [setOpenMoreMenuDebounce] = useDebouncedCallback(async (value: boolean) => {
    setOpenMoreMenu(value)
  }, 100)

  let currentSubRoute: NavRouteType | undefined

  const currentRoute: NavRouteType | undefined = filteredRoutes.find(r => {
    const pathMatcher = (route: NavRouteType): match<{}> | null =>
      matchPath(history.location.pathname, {
        path: (route.subPaths || []).concat(route.path) || route.path,
        exact: true,
      })
    const mainMatch = pathMatcher(r)

    if (mainMatch) {
      return true
    } else if (r.subRoutes) {
      const srMatch = r.subRoutes.find(sr => {
        const subMatch = pathMatcher(sr)

        if (subMatch) {
          return true
        } else if (sr.subRoutes) {
          return sr.subRoutes.find(ssr => pathMatcher(ssr))
        }

        return false
      })

      if (srMatch) {
        currentSubRoute = srMatch
        return true
      }
    }

    return false
  })

  const [, forceUpdate] = React.useState()
  const moreButtonRef = React.useRef<HTMLButtonElement | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buttonRefs = useCallbackRef<any>(
    (currentRoute?.subRoutes || []).map(() => React.createRef()),
    forceUpdate,
  )
  React.useEffect(() => {
    buttonRefs.current = (currentRoute?.subRoutes || []).map(() => React.createRef())
  }, [currentRoute, buttonRefs])

  const useTotalsConfByPath: { [key: string]: { protocol: string } } = {
    '/http': { protocol: 'http' },
    '/tcp': { protocol: 'tcp' },
    '/udp': { protocol: 'udp' },
  }
  const useTotalsConf = currentRoute && useTotalsConfByPath[currentRoute.path]
  const { routers, services, middlewares } = useTotals(useTotalsConf ? useTotalsConf : { enabled: false })

  const totalValueByPath: { [key: string]: number } = {
    '/http/routers': routers,
    '/http/services': services,
    '/http/middlewares': middlewares,
    '/tcp/routers': routers,
    '/tcp/services': services,
    '/udp/routers': routers,
    '/udp/services': services,
  }

  if (currentRoute?.subRoutes && !buttonRefs.current.length) {
    return null
  }

  return (
    <>
      <GlobalStyle />
      <Nav>
        <NavContainer>
          <NavItem sx={{ alignItems: 'center' }} onClick={(): void => navigate('/')}>
            <Logo as="button" />
            {versionData && (
              <Tooltip label={versionData.Version} action="copy">
                <VersionText>{versionData.Version.toString().substr(0, 6)}</VersionText>
              </Tooltip>
            )}
          </NavItem>
          <NavGroups>
            <FaencyNavGroup variant="left">
              {filteredRoutes.map(route => (
                <NavBarItem
                  {...route}
                  key={route.path}
                  isActive={currentRoute?.path === route.path}
                  isDisabled={route.path === '/mesh' && !clusterInfo?.meshEnabled}
                />
              ))}
            </FaencyNavGroup>
            <NavGroup variant="right">
              {window.navigator.onLine && (
                <PlatformAuthState
                  setPlatformParam={setPlatformParam}
                  setPlatformPath={setPlatformPath}
                  authRedirectUrl={authRedirectUrl}
                  instanceInfos={instanceInfos}
                />
              )}
              <div
                onMouseEnter={(): void => {
                  setOpenMoreMenuDebounce(true)
                }}
                onMouseLeave={(): void => {
                  setOpenMoreMenuDebounce(false)
                }}
              >
                <FaencyNavItem
                  as="button"
                  ref={moreButtonRef}
                  variant={history.location.pathname === '/license' ? 'active' : 'normal'}
                >
                  <Icon name="question-mark-circle-outline" size="large" fill="currentColor" />
                </FaencyNavItem>
                <Menu buttonRef={moreButtonRef} isOpen={openMoreMenu}>
                  <MenuItem
                    label="License"
                    onSelect={(): void => {
                      navigate('/license')
                      setOpenMoreMenuDebounce(false)
                    }}
                  />
                  <MenuItem
                    label="Documentation"
                    onSelect={(): void => {
                      window.open(process.env.REACT_APP_DOCS_URL || 'https://docs.containo.us', '_blank')
                      setOpenMoreMenuDebounce(false)
                    }}
                  />
                </Menu>
              </div>
            </NavGroup>
          </NavGroups>
        </NavContainer>
      </Nav>
      {currentRoute?.subRoutes && (
        <SubNav data-testid="subnavbar">
          <SubNavContainer>
            {currentRoute.subRoutes.map((route, index) => (
              <div
                key={route.path}
                onMouseEnter={(): void => {
                  setOpenMenuDebounce(route.path)
                }}
                onMouseLeave={(): void => {
                  setOpenMenuDebounce(null)
                }}
              >
                <SubNavItem
                  as="button"
                  ref={buttonRefs.current[index]}
                  onClick={(): void => navigate(route.path)}
                  variant={currentSubRoute?.path === route.path ? 'active' : 'normal'}
                >
                  {route.label}
                  {exists(totalValueByPath[route.path]) && (
                    <Chip variant={currentSubRoute?.path === route.path ? 'blue' : 'gray'} ml={1}>
                      {totalValueByPath[route.path]}
                    </Chip>
                  )}
                </SubNavItem>

                <Menu id={route.path} isOpen={openMenu === route.path} buttonRef={buttonRefs.current[index]}>
                  {(route?.subRoutes || []).map(subRoute => (
                    <MenuItem
                      key={subRoute.path}
                      label={subRoute.label}
                      onSelect={(): void => {
                        navigate(subRoute.path)
                        setOpenMenuDebounce(null)
                      }}
                    />
                  ))}
                </Menu>
              </div>
            ))}
          </SubNavContainer>
        </SubNav>
      )}
      <AnimatePresence>
        {platformPath && (
          <PlatformPanel
            platformPath={platformPath}
            setPlatformPath={setPlatformPath}
            instanceInfos={instanceInfos}
            authRedirectUrl={authRedirectUrl}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Header
