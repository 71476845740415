import React from 'react'
import { Text } from '@containous/faency'
import styled, { createGlobalStyle, css } from 'styled-components'
import { motion } from 'framer-motion'
import IframeResizer from 'iframe-resizer-react'
import qs from 'query-string'

const PreventBodyScroll = createGlobalStyle`
  #root {
    overflow: hidden;
    height: 100vh;
  }
`

const IFrameWrapper = styled(motion.div)`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  background-color: rgba(255, 255, 255, 0.47);
  cursor: pointer;
`

const iframePanelCSS = css`
  position: fixed;
  width: 80vw;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgb(245, 247, 251);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
`

const IFrameContainer = styled(motion.div)`
  ${iframePanelCSS}
  box-shadow: 2px 0 12px 0 rgba(0, 0, 0, 0.5);
`

const IFrameLoading = styled(motion.div)`
  ${iframePanelCSS}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  color: rgba(0, 0, 0, 0.1);

  img {
    margin-bottom: 12px;
  }
`

const PilotLoadingImg = styled.img.attrs({
  src: 'img/pilot.png',
  alt: 'Traefik Pilot',
})`
  height: 250px;
`

type PlatformPanelProps = {
  platformPath: string | null
  setPlatformPath: (path: string | null) => void
  authRedirectUrl: string
  instanceInfos: string
}

export const PlatformPanel: React.FC<PlatformPanelProps> = ({
  platformPath,
  setPlatformPath,
  instanceInfos,
  authRedirectUrl,
}) => {
  const [iframeLoading, setIframeLoading] = React.useState(true)
  const iframeSrc = qs.stringifyUrl({
    url: `${process.env.REACT_APP_PILOT_URL}${platformPath}`,
    query: { authRedirectUrl, instanceInfos },
  })

  return (
    <>
      <PreventBodyScroll />
      <IFrameWrapper
        onClick={(): void => setPlatformPath(null)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <IFrameContainer
          initial={{ right: '-1000px', opacity: 0 }}
          animate={{ right: 0, opacity: 1 }}
          exit={{ right: '-1000px', opacity: 0 }}
        >
          <IframeResizer
            src={iframeSrc}
            onMessage={(): null => null}
            onLoad={(): void => setIframeLoading(false)}
            onLoadStart={(): void => setIframeLoading(true)}
            scrolling
            style={{ width: 'auto', minWidth: '100%', height: '100%', border: 'none', overflow: 'auto' }}
          />
          {iframeLoading && (
            <IFrameLoading
              initial={{ right: '-1000px', opacity: 0 }}
              animate={{ right: 0, opacity: 1 }}
              exit={{ right: '-1000px', opacity: 0 }}
            >
              <PilotLoadingImg />
              <Text sx={{ fontSize: 5, fontWeight: 'bold', opacity: 0.5 }}>Loading...</Text>
            </IFrameLoading>
          )}
        </IFrameContainer>
      </IFrameWrapper>
    </>
  )
}
