import React from 'react'
import styled, { SimpleInterpolation } from 'styled-components'
import breakpoints from '../utils/breakpoints'

const StyledImg = styled.img.attrs({
  src: 'traefikee.svg',
  alt: 'Traefik Enterprise',
})`
  height: 40px;
`

type LogoType = {
  as: 'button' | 'a'
}

const Logo = styled.div.attrs<LogoType>({
  children: <StyledImg />,
})`
  display: flex;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;
  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  btn:hover,
  &.a:hover {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: 930px) {
    width: 40px;
    overflow: hidden;
  }

  ${(props: LogoType): SimpleInterpolation =>
    props.as &&
    ['button', 'as'].includes(props.as) &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`

export default Logo
