import React, { useState, useEffect } from 'react'
import Page from '../../Page'
import { Box, Flex, Heading as FaencyHeading, Text, theme, Thead, Th, Tr, Td, Tfoot, Tooltip } from '@containous/faency'
import Icon from 'react-eva-icons'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import exists from '../../../utils/exists'
import FeatureCard from '../../FeatureCard'
import { StatusDots } from '../../StatusDots'
import useSWR from 'swr'
import { RenderRowType } from '../../../hooks/use-fetch-with-pagination'
import { AnimatedTable, AnimatedTBody, AnimatedRow } from '../../AnimatedTable'
import { ResourceStatus } from '../../ResourceStatus'
import { EmptyPlaceholder } from '../../EmptyPlaceholder'
import { SpinnerLoader } from '../../SpinnerLoader'
import { StatusCard } from '../../StatusCard'
import { ChipLimited } from '../../ChipLimited'
import { useHistory } from 'react-router-dom'

const Heading = styled(FaencyHeading)`
  align-items: center;
  display: flex;

  i {
    padding-right: 8px;
  }
`

const CardWrapper = styled(Box)`
  display: grid;
  margin-left: -${theme.space[2]};
  margin-right: -${theme.space[2]};
  border-radius: 12px;
  overflow: hidden;
`

const Grid = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(426px, 1fr));
  grid-gap: 30px;
`

const AnimatedCardWrapper = motion.custom(CardWrapper)
const AnimatedGrid = motion.custom(Grid)

const renderRow: RenderRowType = ({ row }) => (
  <AnimatedRow key={row.name}>
    <Td style={{ width: '25%' }}>
      <ResourceStatus status={row.status} />
    </Td>
    <Td style={{ width: '25%' }}>{row.type}</Td>
    <Td style={{ width: '25%' }}>
      <Tooltip label={row.name} action="copy">
        <ChipLimited variant="blue" truncate>
          {row.name}
        </ChipLimited>
      </Tooltip>
    </Td>
    <Td style={{ width: '25%' }}>
      <Text sx={{ color: row.status.toLowerCase() === 'error' ? 'red' : 'orange', fontWeight: 600 }}>
        {row.message}
      </Text>
    </Td>
  </AnimatedRow>
)

export const MeshOverview: React.FC = () => {
  const [isMounted, setMounted] = useState(false)
  const history = useHistory()
  const { data } = useSWR('/mesh/overview')
  const { data: meshErrors } = useSWR('/mesh/errors')

  useEffect(() => {
    setMounted(true)
  }, [])

  const animatedListProps = {
    variants: {
      hidden: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
      visible: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
      },
    },
  }

  const animatedRowProps = {
    variants: {
      hidden: {
        x: -6,
        opacity: 0,
      },
      visible: {
        x: 0,
        opacity: 1,
      },
    },
    initial: 'hidden',
  }

  return (
    <Page>
      <AnimatedGrid animate={isMounted ? 'visible' : 'hidden'} {...animatedListProps}>
        {exists(data?.service) && (
          <Box mb={3} data-testid="service-block">
            <Heading mb={1} data-testid="service-resources-title">
              <Icon name="cube-outline" size="large" fill="black" />
              <Text mr={2}>Services</Text>
              <StatusDots stats={data.service} />
            </Heading>
            <motion.div {...animatedRowProps}>
              <StatusCard stats={data.service} onExplore={(): void => history.push('/mesh/services')} />
            </motion.div>
          </Box>
        )}
        {exists(data?.acl) && (
          <Box mb={3} data-testid="acl-block">
            <Heading mb={1} data-testid="acl-resources-title">
              <Icon name="globe-2-outline" size="large" fill="black" />
              <Text mr={2}>ACL</Text>
              <StatusDots stats={data.acl} />
            </Heading>
            <motion.div {...animatedRowProps}>
              <StatusCard stats={data.acl} />
            </motion.div>
          </Box>
        )}
        {exists(data?.trafficSplit) && (
          <Box mb={3} data-testid="trafficSplit-block">
            <Heading mb={1} data-testid="trafficSplit-resources-title">
              <Icon name="shuffle-outline" size="large" fill="black" />
              <Text mr={2}>TrafficSplits</Text>
              <StatusDots stats={data.trafficSplit} />
            </Heading>
            <motion.div {...animatedRowProps}>
              <StatusCard stats={data.trafficSplit} />
            </motion.div>
          </Box>
        )}
      </AnimatedGrid>
      <Box mb={3} data-testid="errors-block">
        <Heading mb={1}>
          <Icon name="alert-triangle-outline" size="large" fill="black" />
          Errors
        </Heading>
        {/* InfiniteRef and pageCount set to fixed values until we have pagination for Mesh Errors */}
        <AnimatedTable infiniteRef={null}>
          <Thead>
            <Tr>
              <Th style={{ width: '20%' }}>Status</Th>
              <Th style={{ width: '20%' }}>Type</Th>
              <Th style={{ width: '20%' }}>Name</Th>
              <Th style={{ width: '40%' }}>Message</Th>
            </Tr>
          </Thead>
          <AnimatedTBody pageCount={1} isMounted={isMounted}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {meshErrors && meshErrors.map((row: any): JSX.Element => renderRow({ row }))}
          </AnimatedTBody>
          {meshErrors && meshErrors.length === 0 && (
            <Tfoot>
              <Tr>
                <td colSpan={100}>
                  <EmptyPlaceholder />
                </td>
              </Tr>
            </Tfoot>
          )}
        </AnimatedTable>
        {!meshErrors && (
          <Flex sx={{ height: 60, alignItems: 'center', justifyContent: 'center' }}>
            <SpinnerLoader />
          </Flex>
        )}
      </Box>
      {data?.features && Object.keys(data?.features).length > 0 && (
        <Box mb={3} data-testid="features-block">
          <Heading mb={1}>
            <Icon name="toggle-right-outline" size="large" fill="black" />
            Features
          </Heading>
          <AnimatedCardWrapper
            style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))' }}
            animate={isMounted ? 'visible' : 'hidden'}
            {...animatedListProps}
          >
            {Object.entries<string | boolean>(data.features).map(([name, value]) => (
              <motion.div key={name} {...animatedRowProps}>
                <FeatureCard name={name} value={value} />
              </motion.div>
            ))}
          </AnimatedCardWrapper>
        </Box>
      )}
    </Page>
  )
}
